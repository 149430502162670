import {apiSlice} from '../../app/api/apiSlice';
import { Rule } from '../Rule/types';
import {type Review} from './types'; // Definieren Sie Ihren Review-Typ

export const ReviewApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getReviewsByClause: builder.query<Review[], string>({
            query: (clauseId) => `/reviews/byClause/${clauseId}`,
            providesTags: ['Review']
        }),
        getRulesByReviewId: builder.query<Rule[], string>({
            query: (reviewId) => `/reviews/rules/${reviewId}`
        }),
        createReview: builder.mutation<Review, { assessmentUser: string, clause: string, comment?: string }>({
            query: (reviewData) => ({
                url: `/reviews`,
                method: 'POST',
                body: reviewData
            }),
            invalidatesTags: ['Review', 'Clause', 'Paragraph']
        })
    })
});

export const {
    useGetReviewsByClauseQuery,
    useGetRulesByReviewIdQuery,
    useCreateReviewMutation
} = ReviewApiSlice;

export const {
    endpoints: {
        getReviewsByClause,
        getRulesByReviewId,
        createReview
    }
} = ReviewApiSlice;
