import React from 'react';
import {Box, Card, IconButton, Menu, MenuItem, Tooltip, Typography} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import {useAppDispatch} from '../../../hooks/typedReduxHooks';
import {setSelectedClauseId} from '../../../features/Clause/ClauseSlice';
import {useGetAlternativesByClauseQuery, useUpdateAlternativeMutation} from '../../../features/Alternative/AlternativeApiSlice';

interface AlternativeInfoProps {
    clauseId: string
}

const AlternativeInfo: React.FC<AlternativeInfoProps> = ({clauseId}) => {
    const {data: alternatives} = useGetAlternativesByClauseQuery({clauseId});
    const [updateAlternative] = useUpdateAlternativeMutation();

    const dispatch = useAppDispatch();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (): void => {
        setAnchorEl(null);
    };

    return (
        <Card sx={{
            marginLeft: 1,
            p: 1.5,
            width: '100%',
            minHeight: '39px'
        }}>
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
            }}>
                <Tooltip title="Optionen" arrow>
                    <IconButton onClick={handleClick}>
                        <MoreVertIcon/>
                    </IconButton>
                </Tooltip>
                <Menu id="options-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                    <MenuItem onClick={(e) => {
                        alternatives?.filter((alternative) => alternative.selected).forEach((alternative) => {
                            updateAlternative({
                                alternativeId: alternative._id,
                                requestBody: {selected: false}
                            }).catch((error) => {
                                console.log(error);
                            });
                        });
                        handleClose();
                    }} disabled={alternatives?.every((alternative) => !alternative.selected)}>
                        <RestartAltIcon fontSize="small" sx={{marginRight: 1}}/> Zu originaler Formulierung zurückkehren
                    </MenuItem>
                </Menu>
                <Typography fontWeight="bold"> Alternative Formulierungen </Typography>
                <IconButton onClick={() => { dispatch(setSelectedClauseId('')); }}>
                    <CloseIcon/>
                </IconButton>
            </Box>
        </Card>);
};

export default AlternativeInfo;
