import {type Review} from '../Review/types';

export interface Clause {
    _id: string
    pageNumber: number
    content: string
    polygons: number[]
    paragraph: string
    position: number
    changed: boolean
    assessmentLLM: Assessment
    assessmentUser: Assessment
    latestReview: Review | undefined
}

export enum Assessment {
    VALID = 'valid', UNFAIR = 'unfair', VOID = 'void', TOREASSESS = 'toreassess'
}

export interface ClauseSliceInterface {
    selectedClauseId: string
    executeScroll: boolean
    editClauseId: string
    newClauseText: string
}
