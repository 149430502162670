import {Box, CircularProgress} from '@mui/material';
import React from 'react';

const LoadingSpinner = (): any => {
    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'center'
        }}>
            <CircularProgress/>
        </Box>);
};

export default LoadingSpinner;
