import {configureStore} from '@reduxjs/toolkit';
import ClauseReducer from '../features/Clause/ClauseSlice';
import ContractReducer from '../features/Contract/ContractSlice';
import AlternativeReducer from '../features/Alternative/AlternativeSlice';
import ParagraphReducer from '../features/Paragraph/ParagraphSlice';
import {apiSlice} from './api/apiSlice';
// import { apiSliceAlternative } from './api/apiSliceAlternative';

// Why do we need this?
export const store = configureStore({
    reducer: {
        /* Contract */
        contract: ContractReducer, /* Paragraph */
        paragraph: ParagraphReducer, /* Clause */
        clause: ClauseReducer, /* Alternative */
        alternative: AlternativeReducer, /* API */
        [apiSlice.reducerPath]: apiSlice.reducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({serializableCheck: false}).concat(// apiSlice.middleware,
        apiSlice.middleware),
    devTools: true
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
