import {createTheme} from '@mui/material';

declare module '@mui/material/styles' {
    interface Palette {
        tertiary: {
            main: string
        }
        assessment: {
            valid: string
            unfair: string
            void: string
            toreassess: string
        }
    }

    interface PaletteOptions {
        tertiary: {
            main: string
        }
        assessment: {
            valid: string
            unfair: string
            void: string
            toreassess: string
        }
    }
}


const lightTheme = createTheme({
    palette: {
        primary: {
            main: '#13274F'
        },
        secondary: {
            main: '#5386eb'
        },
        background: {
            default: '#f4f4f4'
        },
        tertiary: {
            main: '#ececec'
        },
        text: {
            primary: '#000000',
            secondary: '#868686'
        },
        assessment: {
            valid: '#8DB255',
            unfair: '#E2B13C',
            void: '#D24D57',
            toreassess: '#3c62ad'
        }
    },
    typography: {
        fontFamily: 'Arial',
        fontSize: 12
    },
    components: {
        MuiCard: {
            styleOverrides: {
                root: {
                    borderRadius: 16,
                    boxShadow: 'none'
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 10
                }
            }
        },
        MuiStepLabel: {
            styleOverrides: {
                label: {
                    fontSize: 15
                }
            }
        }
    }
});

export {lightTheme};
