import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import HistoryIcon from "@mui/icons-material/History";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import ArchiveIcon from "@mui/icons-material/Archive";
import {
    Box,
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Tooltip,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from "@mui/material";
import { Rule } from "../../features/Rule/types";
import RuleModal from "./RuleModal";

interface RuleTableProps {
    rules: Rule[];
    handleEdit: (rule: Rule) => Promise<void>;
    handleArchiveToggle: (ruleId: string) => Promise<void>;
    showArchived: boolean;
}

export default function RuleTable(props: RuleTableProps) {
    const [modalOpen, setModalOpen] = React.useState(false);
    const [selectedRule, setSelectedRule] = React.useState<Rule | null>(null);
    const [confirmDialog, setConfirmDialog] = React.useState<{
        open: boolean;
        rule: Rule | null;
    }>({ open: false, rule: null });

    const handleModalOpen = (rule: Rule) => {
        setSelectedRule(rule);
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);
        setSelectedRule(null);
    };

    const handleArchiveClick = (rule: Rule) => {
        setConfirmDialog({ open: true, rule });
    };

    const handleConfirmArchive = async () => {
        if (confirmDialog.rule) {
            try {
                await props.handleArchiveToggle(confirmDialog.rule.ruleId.toString());
                setConfirmDialog({ open: false, rule: null });
            } catch (error) {
                console.error("Error toggling archive status:", error);
            }
        }
    };

    return (
        <>
            <TableContainer component={Paper} sx={{ maxHeight: '75vh' }}>
                <Table>
                    <TableHead>
                        <TableRow sx={{
                            backgroundColor: "#fcfcfc",
                        }}>
                            <TableCell width="15%">
                                <Box sx={{ textAlign: "left" }}>
                                    Thema
                                </Box>
                            </TableCell>
                            <TableCell width="70%">
                                <Box sx={{ textAlign: "left" }}>
                                    Regeln
                                </Box>
                            </TableCell>
                            <TableCell width="15%">
                                <Box sx={{ textAlign: "right" }}></Box>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.rules?.map((rule, index) => (
                            <TableRow key={`${rule.ruleId}-${index}`}>
                                <TableCell>
                                    <Box sx={{ textAlign: "left" }}>
                                        <Typography>{rule.topic}</Typography>
                                    </Box>
                                </TableCell>
                                <TableCell>
                                    <Box sx={{ textAlign: "left" }}>
                                        {rule.references?.length > 0 ? (
                                            <Accordion
                                                sx={{
                                                    boxShadow: 'none',
                                                    '&:before': {
                                                        display: 'none',
                                                    },
                                                }}
                                            >
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    sx={{
                                                        padding: 0,
                                                        minHeight: '30px',
                                                    }}
                                                >
                                                    <Typography color="primary">
                                                        {rule.text}
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails sx={{ padding: '0' }}>
                                                    {rule.references.map((reference, refIndex) => (
                                                        <Typography
                                                            key={refIndex}
                                                            sx={{
                                                                fontSize: '0.875rem',
                                                                color: 'text.primary',
                                                                marginLeft: 1
                                                            }}
                                                        >
                                                            - {reference}
                                                        </Typography>
                                                    ))}
                                                </AccordionDetails>
                                            </Accordion>
                                        ) : (
                                            <Typography style={{ whiteSpace: "pre-line", marginBottom: rule.references?.length ? 1 : 0 }}>
                                                {rule.text}
                                            </Typography>   
                                        )}
                                    </Box>
                                </TableCell>
                                <TableCell>
                                    <Box sx={{ textAlign: "right", display: "flex", justifyContent: "right" }}>
                                        <Tooltip title="Bearbeiten" placement="top">
                                            <Button
                                                variant="text"
                                                color="secondary"
                                                onClick={() => props.handleEdit(rule)}
                                                sx={{
                                                    minWidth: "auto",
                                                    "&:hover": {
                                                        color: "blue",
                                                    },
                                                }}
                                            >
                                                <EditIcon />
                                            </Button>
                                        </Tooltip>
                                        <Tooltip title="Versionen" placement="top">
                                            <Button
                                                variant="text"
                                                onClick={() => handleModalOpen(rule)}
                                                sx={{
                                                    color: "orange",
                                                    minWidth: "auto",
                                                    "&:hover": {
                                                        color: "darkorange",
                                                    },
                                                }}
                                            >
                                                <HistoryIcon />
                                            </Button>
                                        </Tooltip>
                                        <Tooltip title={props.showArchived ? "Wiederherstellen" : "Archivieren"}>
                                            <Button
                                                variant="text"
                                                onClick={() => handleArchiveClick(rule)}
                                                sx={{
                                                    color: rule.isArchived ? "#3979d2" : "#d23939",
                                                    minWidth: "auto",
                                                    "&:hover": {
                                                        color: rule.isArchived ? "#23487a" : "#6e1d1d",
                                                    },
                                                }}
                                            >
                                                {rule.isArchived ? <UnarchiveIcon /> : <ArchiveIcon />}
                                            </Button>
                                        </Tooltip>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <RuleModal
                open={modalOpen}
                onClose={handleModalClose}
                rule={selectedRule}
                // handleArchiveToggle={props.handleArchiveToggle}
            />

            <Dialog
                open={confirmDialog.open}
                onClose={() => setConfirmDialog({ open: false, rule: null })}
            >
                <DialogTitle>
                    {props.showArchived ? "Regel wiederherstellen?" : "Regel archivieren?"}
                </DialogTitle>
                <DialogContent>
                    <Typography>
                        {props.showArchived
                            ? `Möchten Sie die Regel "${confirmDialog.rule?.topic}" wirklich wiederherstellen?`
                            : `Möchten Sie die Regel "${confirmDialog.rule?.topic}" wirklich archivieren?`}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setConfirmDialog({ open: false, rule: null })}
                        color="primary"
                    >
                        Abbrechen
                    </Button>
                    <Button
                        onClick={handleConfirmArchive}
                        variant="contained"
                        color={props.showArchived ? "primary" : "error"}
                    >
                        {props.showArchived ? "Wiederherstellen" : "Archivieren"}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}