import {apiSlice} from '../../app/api/apiSlice';
import {CreateTemplateClauseRequest} from './types';
import {type TemplateClause} from './types';

export const TemplateClauseApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getAllTemplateClauses: builder.query<TemplateClause[], void>({
            query: () => `/templateclause`,
            providesTags: ['TemplateClause']
        }),
        getAllTopics: builder.query<string[], void>({
            query: () => `/topics`
        }),
        postNewTemplateClause: builder.mutation<TemplateClause, CreateTemplateClauseRequest>({
            query: (newTemplateClause) => ({
                url: `/templateclause`,
                method: 'POST',
                body: newTemplateClause
            }),
            invalidatesTags: ['TemplateClause', 'Alternative']
        }),
        updateTemplateClause: builder.mutation<TemplateClause, { id: string, text: string, topic: string }>({
            query: ({ id, text, topic }) => ({
                url: `/templateclause/${id}`,
                method: 'PUT',
                body: { text, topic }
            }),
            invalidatesTags: ['TemplateClause', 'Alternative']
        }),
        deleteTemplateClause: builder.mutation<void, string>({
            query: (templateClauseId) => ({
                url: `/templateclause/${templateClauseId}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['TemplateClause', 'Alternative']
        }),
    })
});

export const {
    useGetAllTemplateClausesQuery,
    usePostNewTemplateClauseMutation,
    useDeleteTemplateClauseMutation,
    useUpdateTemplateClauseMutation

} = TemplateClauseApiSlice;

export { TemplateClause };