import React from 'react';
import { Box, Typography, Paper, Grid } from '@mui/material';
import { Description, Topic, Rule, Person, AutoAwesome } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';

interface MetricCardProps {
  icon: React.ReactNode;
  title: string;
  value: string | number;
}

const MetricCard: React.FC<MetricCardProps> = ({ icon, title, value }) => {
  const theme = useTheme();
  return (
    <Paper elevation={0} sx={{ p: 2, display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'space-between', gap: 3, flex: '1 1 0', height: 'revert' }}>
        {icon}
        <Box>
            <Typography variant="body1" fontWeight="bold" sx={{ color: theme.palette.grey[400] }}>{title}</Typography>
            <Typography variant="h5" sx={{ fontWeight: 'bold' }}>{value}</Typography>
        </Box>
    </Paper>
  );
};

interface BasicMetasProps {
  basicMeta: {
    contracts: number;
    topics: any[];
    rules: any[];
    users: number;
    llm: string;
  };
}

const BasicMetas: React.FC<BasicMetasProps> = ({ basicMeta }) => {
  const theme = useTheme();

  const metricIcons: { [key: string]: React.ReactNode } = {
    contracts: <Description sx={{ backgroundColor: theme.palette.grey[200], padding: 2, borderRadius: 100 }} />,
    topics: <Topic sx={{ backgroundColor: theme.palette.grey[200], padding: 2, borderRadius: 100 }} />,
    rules: <Rule sx={{ backgroundColor: theme.palette.grey[200], padding: 2, borderRadius: 100 }} />,
    users: <Person sx={{ backgroundColor: theme.palette.grey[200], padding: 2, borderRadius: 100 }} />,
    llm: <AutoAwesome sx={{ backgroundColor: theme.palette.grey[200], padding: 2, borderRadius: 100 }} />
  };

  const metricKeys: { [key: string]: React.ReactNode } = {
    contracts: 'Anzahl Verträge',
    topics: 'Anzahl Themen',
    rules: 'Anzahl Prüfanleitungen',
    users: 'Anzahl Benutzer',
    llm: 'Akutelles LLM'
  }

  return (
    <Box>
      <Box sx={{ 
        display: "flex", flexDirection: "row", gap: 3, flexWrap: "wrap"
      }}>
        {Object.entries(basicMeta).map(([key, value]) => {
          
          const metricValue = typeof value === 'string' || typeof value === 'number' ? value : value.length;
          return (
            <MetricCard
                key={key}
                icon={metricIcons[key] || <Description sx={{ fontSize: 40, color: theme.palette.grey[500] }} />}
                title={metricKeys[key] as string}
                value={metricValue}
            />
          );
        })}
      </Box>
    </Box>
  );
};

export default BasicMetas;