import { useEffect, useMemo, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Box, Button, CircularProgress, Typography } from '@mui/material';

import TopicTable from "./TopicTable";
import { useGetAllRulesQuery } from "../../features/Rule/RuleApiSlice";
import { useGetAllTopicsQuery, useDeleteTopicMutation, useUpdateTopicMutation, useCreateTopicMutation } from "../../features/Topic/TopicApiSlice";
import { useGetAllTemplateClausesQuery } from "../../features/TemplateClause/TemplateClauseApiSlice";
import { Topic } from "../../features/Topic/types";

const TopicOverview = (): JSX.Element => {
    const [open, setOpen] = useState(false);

    // Queries with refetch
    const { data: topics, refetch: refetchTopics } = useGetAllTopicsQuery();
    const { data: rules, refetch: refetchRules } = useGetAllRulesQuery();
    const { data: templateClauses, refetch: refetchTemplateClauses } = useGetAllTemplateClausesQuery();

    // Mutations
    const [createTopic, { isLoading: createLoading }] = useCreateTopicMutation();
    const [updateTopic, { isLoading: updateLoading }] = useUpdateTopicMutation();
    const [deleteTopic] = useDeleteTopicMutation();

    const [currentTopic, setCurrentTopic] = useState<Topic>({
        title: "",
        isDeleted: false,
    });

    const topicsWithMetadata = useMemo(() => {
        const map = new Map<Topic, { ruleCount: number; templateCount: number; isDeletable: boolean }>();
        
        if (!topics || !rules || !templateClauses) return map;

        const activeTopics = topics.filter(topic => !topic.isDeleted);

        activeTopics.forEach((topic) => {
            if (topic._id) {
                // Count rules and template clauses for this topic
                const ruleCount = rules.filter(rule => rule.topic === topic._id).length;
                const templateCount = templateClauses.filter(clause => clause.topic === topic._id).length;
                
                // Topic is deletable only if it has no rules and no template clauses
                const isDeletable = ruleCount === 0 && templateCount === 0;

                map.set(topic, {
                    ruleCount,
                    templateCount,
                    isDeletable
                });
            }
        });

        return map;
    }, [rules, topics, templateClauses]);

    const handleDeleteButton = async (title: string): Promise<void> => {
        try {
            const topic = Array.from(topicsWithMetadata.entries())
                .find(([t]) => t.title === title)?.[0];

            if (!topic) return;

            const metadata = topicsWithMetadata.get(topic);
            
            if (!metadata?.isDeletable) {
                console.error('Topic cannot be deleted because it has associated rules or template clauses');
                return;
            }

            await deleteTopic(title);
            await Promise.all([
                refetchTopics(),
                refetchRules(),
                refetchTemplateClauses()
            ]);
        } catch (error) {
            console.error('Failed to delete topic:', error);
        }
    };

    const handleCreateNewButton = (): void => {
        setCurrentTopic({
            title: "",
            isDeleted: false,
        });
        setOpen(true);
    };

    const handleClose = (): void => {
        setOpen(false);
    };

    const saveEntry = async (): Promise<void> => {
        try {
            if (!currentTopic.title) return;

            if (currentTopic._id) {
                await updateTopic({ id: currentTopic._id, title: currentTopic.title }).unwrap();
            } else {
                await createTopic(currentTopic.title).unwrap();
            }

            await Promise.all([
                refetchTopics(),
                refetchRules(),
                refetchTemplateClauses()
            ]);
            handleClose();
        } catch (error) {
            console.error("Failed to save topic:", error);
        }
    };

    const handleEditButton = async (topic: Topic): Promise<void> => {
        setCurrentTopic({ ...topic });
        setOpen(true);
    };

    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
        }}>
            <Box sx={{
                mt: 5,
                width: '100%'
            }}>
                <Typography variant="h4">Themen</Typography>

                <Dialog fullWidth={true} maxWidth={'md'} open={open} onClose={handleClose}>
                    {/* Dialog content remains the same */}
                    <DialogContent sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}>
                        <Box sx={{
                            width: '100%',
                            margin: 'auto'
                        }}>
                            <Typography variant="h5" sx={{ marginBottom: '1rem' }}>
                                {currentTopic._id ? "Thema bearbeiten" : "Neues Thema erstellen"}
                            </Typography>
                            
                            <TextField 
                                value={currentTopic.title} 
                                label="Thema... (zB.: Vergütung)" 
                                fullWidth 
                                margin="normal" 
                                sx={{
                                    width: '100%',
                                    marginBottom: '1rem',
                                    display: 'block',
                                    overflowWrap: 'break-word'
                                }} 
                                onChange={(e) => {
                                    setCurrentTopic(prev => ({
                                        ...prev,
                                        title: e.target.value
                                    }));
                                }} 
                            />
                        </Box>

                        <Box sx={{
                            width: '100%',
                            maxWidth: '300px',
                            margin: 'auto',
                            marginTop: '2rem'
                        }}>
                            <Button 
                                type="submit" 
                                variant="contained" 
                                color="primary" 
                                onClick={saveEntry}
                                fullWidth 
                            >
                                {createLoading || updateLoading ? 
                                    <CircularProgress color="secondary" /> : 
                                    'Speichern'}
                            </Button>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Abbrechen</Button>
                    </DialogActions>
                </Dialog>

                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}>
                    <Box sx={{ display: 'flex', gap: 2 }}>
                        <Button 
                            variant="outlined" 
                            startIcon={<AddIcon />} 
                            sx={{ my: 4 }} 
                            onClick={handleCreateNewButton}
                        >
                            Neues Thema
                        </Button>
                    </Box>
                </Box>

                <TopicTable 
                    topics={topicsWithMetadata} 
                    handleDeleteButton={handleDeleteButton} 
                    handleEdit={handleEditButton} 
                />
            </Box>
        </Box>
    );
};

export default TopicOverview;