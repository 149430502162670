import { Box, Button, Divider, TextField } from '@mui/material';
import React, { useState } from 'react';
import { type Clause } from '../../../features/Clause/types';
import { ContractState } from '../../../features/Contract/types';
import { useCreateReviewMutation, useGetReviewsByClauseQuery } from '../../../features/Review/ReviewApiSlice';
import { useUpdateContractMutation, useGetContractByIdQuery } from '../../../features/Contract/ContractApiSlice';
import ClassificationLawyer from './ClassificationLawyer';
import { useParams } from 'react-router-dom';

interface ClassificationLawyersProps {
    clause: Clause;
}

const ClassificationLawyers: React.FC<ClassificationLawyersProps> = ({ clause }) => {
    const { _id } = useParams();
    
    // Queries with refetch capability
    const { 
        data: contract,
        refetch: refetchContract 
    } = useGetContractByIdQuery(_id);
    
    const { 
        data: reviews = [],
        refetch: refetchReviews
    } = useGetReviewsByClauseQuery(clause._id);

    // Mutations
    const [createReview] = useCreateReviewMutation();
    const [updateContract] = useUpdateContractMutation();

    // Local state
    const [newClassification, setNewClassification] = useState<string>('valid');
    const [newComment, setNewComment] = useState<string>('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleClassificationClick = (classification: string) => {
        setNewClassification(classification);
    };

    const resetForm = () => {
        setNewClassification('valid');
        setNewComment('');
    };

    const createNewReview = async (): Promise<void> => {
        if (isSubmitting) return;
        
        setIsSubmitting(true);
        
        try {
            // Create the review
            const requestBody = {
                comment: newComment,
                clause: clause._id,
                assessmentUser: newClassification,
                isLLM: false
            };

            await createReview(requestBody).unwrap();

            // Update contract state if necessary
            if (contract?.state === ContractState.ALTERNATIVE_FORMULATIONS || 
                contract?.state === ContractState.FINISHED_REVIEW || 
                contract?.state === ContractState.FINISHED) {
                await updateContract({
                    contractId: contract._id,
                    requestBody: { state: ContractState.INTERNAL_CLASSIFICATION }
                }).unwrap();
            }

            // Refetch data to update UI
            await Promise.all([
                refetchContract(),
                refetchReviews()
            ]);

            // Reset form
            resetForm();
        } catch (error) {
            console.error('Error creating review:', error);
            // Here you could add a toast notification or other error feedback
        } finally {
            setIsSubmitting(false);
        }
    };

    const classificationButtons = [
        { value: 'valid', color: 'assessment.valid' },
        { value: 'unfair', color: 'assessment.unfair' },
        { value: 'void', color: 'assessment.void' }
    ];

    return (
        <Box sx={{ mx: 5, mt: 2 }}>
            {reviews.map((review, index) => (
                <React.Fragment key={review._id || index}>
                    {index !== 0 && <Divider />}
                    <ClassificationLawyer review={review} />
                </React.Fragment>
            ))}
            
            {clause.latestReview && <Divider />}
            
            <Box sx={{
                my: 3,
                display: 'flex',
                flexDirection: 'row'
            }}>
                {classificationButtons.map((button) => (
                    <Button
                        key={button.value}
                        onClick={() => handleClassificationClick(button.value)}
                        sx={{
                            borderWidth: newClassification === button.value ? 2 : 1,
                            borderStyle: 'solid',
                            borderColor: newClassification === button.value ? 'primary.main' : 'transparent'
                        }}
                        variant={newClassification === button.value ? 'outlined' : 'text'}
                    >
                        <Box sx={{
                            minWidth: '20px',
                            height: '20px',
                            borderRadius: '50%',
                            backgroundColor: button.color
                        }} />
                    </Button>
                ))}

                <TextField
                    variant="outlined"
                    label="Ihre Bewertung"
                    value={newComment}
                    onChange={(e) => setNewComment(e.target.value)}
                    sx={{
                        width: '100%',
                        mx: 1
                    }}
                />

                <Button
                    onClick={() => void createNewReview()}
                    variant="contained"
                    disabled={isSubmitting}
                >
                    {isSubmitting ? 'Wird gespeichert...' : 'Fertig'}
                </Button>
            </Box>
        </Box>
    );
};

export default ClassificationLawyers;