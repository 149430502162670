import { Box, Card, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetAllContractsQuery } from '../../features/Contract/ContractApiSlice';
import { type Contract } from '../../features/Contract/types';
import SearchBar from '../contractManagement/SearchBar';
import ContractItem from './ContractItem';
import UploadCard from './UploadCard';


const ContractsOverview = () => {
  const navigate = useNavigate();
  // refetch contracts all 5 seconds
  const { data: contracts, refetch } = useGetAllContractsQuery(undefined, { pollingInterval: 5000 });

  const [filteredContracts, setFilteredContracts] = useState<Contract[] | undefined>(contracts);
  const [searchString, setSearchString] = useState<string>('');

  const handleChangeSearchString = (newSearchString: string): void => {
    setSearchString(newSearchString);
  };


  useEffect(() => {
    if (searchString === '') {
      setFilteredContracts(contracts);
    } else {
      setFilteredContracts(
        contracts
          ? contracts.filter((contract) => {
            const titleMatch = contract.title.toLowerCase().includes(searchString.toLowerCase());
            const descriptionMatch = contract.description.toLowerCase().includes(searchString.toLowerCase());
            const industryMatch = contract.industry.toLowerCase().includes(searchString.toLowerCase());
            return titleMatch || descriptionMatch || industryMatch;
          })
          : []
      );
    }
  }, [searchString, contracts]);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        overflowY: 'auto',
        maxHeight: '100vh',
        width: '100%'
      }}>
      <Box
        sx={{
          width: '100%'
        }}>
        <Grid
          container
          spacing={2}
          justifyContent={'left'}
          my={2}
        >
          <Grid
            item
            xs={12}
            md={6}
            lg={6}
          >
            <Typography
              variant='h4'
            >
              Übersicht Verträge
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            lg={6}
          >
            <SearchBar searchString={searchString} changeSearchString={handleChangeSearchString} />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          justifyContent={'left'}
        // sx={{ display: 'flex', alignItems: 'stretch' }}
        >
          <Grid
            item
            xs={4}
            md={6}
            lg={4}
            xl={3}
            sx={{
              display: 'flex',
              justifyContent: 'stretch'
            }}
          >
            <Card
              sx={{
                p: 1,
                backgroundColor: 'tertiary.main',
                width: '100%'
              }}
            >
              <UploadCard />
            </Card>
          </Grid>
          {filteredContracts?.map((contract, index) => (
            <Grid
              item
              xs={4}
              md={6}
              lg={4}
              xl={3}
              key={index}
              onClick={(e) => {
                navigate(`/contracts/${contract._id}`, { state: { contract } })
              }}
            >
              <ContractItem key={contract?._id} contract={contract} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box >
  );
};

export default ContractsOverview;
