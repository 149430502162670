import React from 'react';
import {Box, Typography} from '@mui/material';

const PageNotFound = (): any => {
    return (
        <Box>
            <Typography variant="h2" color="primary" gutterBottom> 404 - Page Not Found </Typography>
            <Typography variant="body1" color="textSecondary"> Sorry, the page you are looking for does not exist. </Typography>
        </Box>);
};

export default PageNotFound;
