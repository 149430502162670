import React, {useState} from 'react';
import {Avatar, ListItem, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem} from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import {useGetCurrentUserQuery, useLogoutMutation} from '../../features/User/UserApiSlice';
import {useNavigate} from 'react-router-dom';

export default function UserInformation() {
    const [logout] = useLogoutMutation();
    const navigate = useNavigate();

    const {
        data: user,
        isLoading
    } = useGetCurrentUserQuery();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const openMenu = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (): void => {
        setAnchorEl(null);
    };

    return (!isLoading ?
        <ListItem disablePadding sx={{display: 'block'}}>
            <ListItemButton sx={{
                minHeight: 48,
                justifyContent: 'center',
                px: 2.5,
                borderRadius: '23px'
            }} onClick={handleClick}>
                <ListItemIcon sx={{
                    minWidth: 0,
                    mr: 0,
                    justifyContent: 'center'
                }}>
                    <Avatar sx={{bgcolor: 'orange'}}>{`${user?.firstName.charAt(0)}${user?.lastName.charAt(0)}`}</Avatar>
                </ListItemIcon>
            </ListItemButton>
            <Menu id="user-menu" anchorEl={anchorEl} open={openMenu} onClose={handleClose} transformOrigin={{
                vertical: 'top',
                horizontal: 'center'
            }} sx={{
                minWidth: 100
            }}>
                <MenuItem onClick={() => {
                    logout().then(() => {
                        navigate('/');
                        window.location.reload();
                    }).catch(() => {
                        console.log('Logout failed');
                    });
                }}>
                    <ListItemIcon><LogoutIcon/></ListItemIcon>
                    <ListItemText>Logout</ListItemText>
                </MenuItem>
            </Menu>
        </ListItem> : <></>);
};