import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import type {RootState} from '../../app/store';
import type {ClauseSliceInterface} from './types';

export const initialState: ClauseSliceInterface = {
    selectedClauseId: '',
    editClauseId: '',
    newClauseText: '',
    executeScroll: false
};

export const ClauseSlice = createSlice({
    name: 'clause',
    initialState,
    reducers: {
        setSelectedClauseId: (state, action: PayloadAction<string>) => {
            state.selectedClauseId = action.payload;
            return state;
        },
        setExecuteScroll: (state, action: PayloadAction<boolean>) => {
            state.executeScroll = action.payload;
            return state;
        },
        setEditClauseId: (state, action: PayloadAction<string>) => {
            state.editClauseId = action.payload;
            return state;
        },
        setNewClauseText: (state, action: PayloadAction<string>) => {
            state.newClauseText = action.payload;
            return state;
        }
    }
});

export const {
    setSelectedClauseId,
    setEditClauseId,
    setNewClauseText,
    setExecuteScroll
} = ClauseSlice.actions;

export const clause = (state: RootState): ClauseSliceInterface => state.clause;

export default ClauseSlice.reducer;
