import { useEffect, useRef, useMemo } from 'react';
import ApexCharts from 'apexcharts';
import { ApexOptions } from 'apexcharts';
import { Typography, Box, Tooltip, IconButton } from '@mui/material';
import { Rule } from '../../features/Rule/types';
import { HelpOutlineOutlined } from '@mui/icons-material';
import { createTopicRuleObject } from '../../helper/topicRuleMap';
import { useGetAllTopicsQuery } from '../../features/Topic/TopicApiSlice';

interface RuleCoverageProps {
    analysisData: Rule[];
}

const RuleCoverage = ({ analysisData }: RuleCoverageProps) => {
    const chartRef = useRef<HTMLDivElement>(null);
    const chartInstance = useRef<ApexCharts | null>(null);
    const { data: topics } = useGetAllTopicsQuery();

    const { chartData, maxCount } = useMemo(() => {
        if (!topics) return { chartData: [], maxCount: 10 };

        const topicRuleMap = createTopicRuleObject(analysisData, topics);
        
        // Convert the map to the format needed for the chart
        const processedData = Object.entries(topicRuleMap)
            .map(([topic, rules]) => ({
                x: topic,
                y: rules.length
            }))
            .sort((a, b) => b.y - a.y);

        return {
            chartData: processedData,
            maxCount: processedData.length > 0 ? processedData[0].y : 10
        };
    }, [analysisData, topics]);

    // Create chart options with memoized values
    const options = useMemo(() => ({
        chart: {
            type: 'bar',
            toolbar: {
                show: false
            }
        },
        series: [{
            name: 'Anzahl Prüfanleitungen',
            data: chartData
        }],
        xaxis: {
            title: { text: 'Anzahl Prüfanleitungen' },
        },
        yaxis: {
            title: { text: 'Themen' },
            labels: {
                show: false
            }
        },
        plotOptions: {
            bar: {
                barHeight: '75%',
                horizontal: true,
                dataLabels: {
                  position: 'bottom'
                },
            }
        },
        dataLabels: {
            enabled: true,
            textAnchor: 'start',
            formatter: function (val: number, opt: any) {
                return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val
            },
            offsetX: 0,
        },
        colors: ['#1e88e5', '#0d47a1'],
        tooltip: {
            y: {
                formatter: function (val: number) {
                    return `${val}`;
                }
            }
        }
    }), [chartData, maxCount]);

    useEffect(() => {
        if (!chartRef.current) return;

        if (chartInstance.current) {
            chartInstance.current.destroy();
        }

        chartInstance.current = new ApexCharts(chartRef.current, options);
        chartInstance.current.render();

        return () => {
            chartInstance.current?.destroy();
        };
    }, [options]);

    return (
        <Box sx={{ padding: 3 }} alignItems="center">
            <Typography variant="h5" gutterBottom>
                Prüfanleitungen pro Thema
                <Tooltip 
                    sx={{ padding: 0, marginLeft: 1 }} 
                    title={
                        <Typography sx={{ margin: 0, padding: 0 }}>
                            <b>Diese Grafik zeigt die Anzahl der Prüfanleitungen pro Thema an.</b> <br />
                            Die Größe und Farbe der Kacheln entspricht der Anzahl der Prüfanleitungen. <br />
                            Grüne Kacheln zeigen Themen mit vielen, rote Kacheln Themen mit weniger Prüfanleitungen.
                        </Typography>
                    } 
                    componentsProps={{
                        tooltip: {
                            sx: {
                                bgcolor: 'common.white',
                                color: 'text.primary',
                                boxShadow: 2,
                            },
                        },
                    }}
                >
                    <IconButton>
                        <HelpOutlineOutlined />
                    </IconButton>
                </Tooltip>
            </Typography>
            <div ref={chartRef} />
        </Box>
    );
};

export default RuleCoverage;