import AddIcon from '@mui/icons-material/Add';
import {Box, Button, Typography, useTheme} from '@mui/material';
import {useState} from 'react';
import FormDialog from '../general/FormDialog';
import CreateUserForm from './CreateUserForm';
import UserTable from './UserTable';

const UserOverview = (): any => {
    const [open, setOpen] = useState(false);

    const handleClickOpen = (): void => {
        setOpen(true);
    };

    const handleClose = (): void => {
        setOpen(false);
    };

    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%'
        }}>
            <Box sx={{
                mt: 5,
                width: '100%'
            }}>
                <Typography variant="h4">Nutzerverwaltung</Typography>
                <FormDialog open={open} handleClose={handleClose}>
                    <CreateUserForm handleClose={handleClose}/>
                </FormDialog>
                <Button variant="outlined" startIcon={<AddIcon />} sx={{ my: 4 }} onClick={handleClickOpen}> Neuen Nutzer anlegen </Button>
                <UserTable />
            </Box>
        </Box>);
};

export default UserOverview;
