import { Topic } from "../features/Topic/types";
import { Rule } from "../features/Rule/types";
import { TemplateClause } from '../features/TemplateClause/types';



export function createTopicRuleMap(rules: Rule[], topics: Topic[]): Map<Topic, Rule[]> {
    const topicRuleMap = new Map<Topic, Rule[]>();
    
    // Filter out deleted topics
    const activeTopics = topics.filter(topic => !topic.isDeleted);
    
    activeTopics.forEach(topic => {
        topicRuleMap.set(topic, []);
    });
    
    // Filter out archived rules and populate the map
    const activeRules = rules.filter(rule => !rule.isArchived);
    
    activeRules.forEach(rule => {
        // Find the corresponding topic
        const topic = activeTopics.find(t => t._id === rule.topic);
        
        if (topic) {
            const existingRules = topicRuleMap.get(topic) || [];
            topicRuleMap.set(topic, [...existingRules, rule]);
        }
    });
    
    return topicRuleMap;
}




export function createTopicRuleObject(rules: Rule[], topics: Topic[]): Record<string, Rule[]> {
    const topicRuleObject: Record<string, Rule[]> = {};
    
    // Filter out deleted topics
    const activeTopics = topics.filter(topic => !topic.isDeleted);
    
    const topicIdToTitle = new Map(activeTopics.map(topic => [topic._id, topic.title]));
    
    activeTopics.forEach(topic => {
        topicRuleObject[topic.title] = [];
    });
    
    // Filter out archived rules and populate the object
    rules.forEach(rule => {
        const topicTitle = topicIdToTitle.get(rule.topic);
        if (topicTitle && topicRuleObject[topicTitle]) {
            topicRuleObject[topicTitle].push(rule);
        }
    });
    
    return topicRuleObject;
}

export function createTopicTemplateClauseObject(templateClauses: TemplateClause[], topics: Topic[]): Record<string, TemplateClause[]> {
    const topicTemplateClauseObject: Record<string, TemplateClause[]> = {};
    
    // Create a map for quick topic ID to title lookup
    const topicIdToTitle = new Map(topics.map(topic => [topic._id, topic.title]));
    
    // Initialize empty arrays for each topic
    topics.forEach(topic => {
        topicTemplateClauseObject[topic.title] = [];
    });
    
    // Populate the object with template clauses
    templateClauses.forEach(clause => {
        const topicTitle = topicIdToTitle.get(clause.topic);
        if (topicTitle && topicTemplateClauseObject[topicTitle]) {
            topicTemplateClauseObject[topicTitle].push(clause);
        }
    });
    
    return topicTemplateClauseObject;
}