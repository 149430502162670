import React, { useMemo, useState } from 'react';
import { 
  Typography,
  Grid,
  FormControl,
  Stack,
  Select,
  MenuItem,
  Button,
  IconButton,
  Box,
  Tooltip,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { Assessment } from '../../features/Clause/types';
import MatrixTable from './MatrixTable';
import { HelpOutlineOutlined } from '@mui/icons-material';

interface TimeRange {
  start: Date;
  end: Date;
  label: string;
}

interface AssessmentPair {
  llmAssessment: Assessment;
  userAssessment: Assessment;
  date: string;
}

interface ConfusionMatrixProps {
  assessmentPairs: AssessmentPair[];
}

const timeRangeOptions = [
  {
    label: 'Letzte 7 Tage',
    getValue: () => ({
      start: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000),
      end: new Date()
    })
  },
  {
    label: 'Letzte 30 Tage',
    getValue: () => ({
      start: new Date(Date.now() - 30 * 24 * 60 * 60 * 1000),
      end: new Date()
    })
  },
  {
    label: 'Letzte 90 Tage',
    getValue: () => ({
      start: new Date(Date.now() - 90 * 24 * 60 * 60 * 1000),
      end: new Date()
    })
  },
  {
    label: 'Dieses Jahr',
    getValue: () => ({
      start: new Date(new Date().getFullYear(), 0, 1),
      end: new Date()
    })
  },
  {
    label: 'Gesamt',
    getValue: () => ({
      start: new Date(0),
      end: new Date()
    })
  }
];

const ConfusionMatrix: React.FC<ConfusionMatrixProps> = ({ assessmentPairs }) => {
  const [primaryRangeIndex, setPrimaryRangeIndex] = useState(3); // Default to 365 days
  const [comparisonRangeIndex, setComparisonRangeIndex] = useState<number | null>(null);

  const primaryRange = useMemo(() => ({
    ...timeRangeOptions[primaryRangeIndex].getValue(),
    label: timeRangeOptions[primaryRangeIndex].label
  }), [primaryRangeIndex]);

  const comparisonRange = useMemo(() => 
    comparisonRangeIndex !== null ? {
      ...timeRangeOptions[comparisonRangeIndex].getValue(),
      label: timeRangeOptions[comparisonRangeIndex].label
    } : null, 
    [comparisonRangeIndex]
  );

  const getMatrixData = (timeRange: TimeRange | null) => {
    const filteredPairs = timeRange 
      ? assessmentPairs.filter(pair => {
          const date = new Date(pair.date);
          return date >= timeRange.start && date <= timeRange.end;
        })
      : assessmentPairs;

    const matrix: Record<Assessment, Record<Assessment, number>> = {
      [Assessment.VALID]: { [Assessment.VALID]: 0, [Assessment.UNFAIR]: 0, [Assessment.VOID]: 0, [Assessment.TOREASSESS]: 0 },
      [Assessment.UNFAIR]: { [Assessment.VALID]: 0, [Assessment.UNFAIR]: 0, [Assessment.VOID]: 0, [Assessment.TOREASSESS]: 0 },
      [Assessment.VOID]: { [Assessment.VALID]: 0, [Assessment.UNFAIR]: 0, [Assessment.VOID]: 0, [Assessment.TOREASSESS]: 0 },
      [Assessment.TOREASSESS]: { [Assessment.VALID]: 0, [Assessment.UNFAIR]: 0, [Assessment.VOID]: 0, [Assessment.TOREASSESS]: 0 }
    };

    filteredPairs.forEach(pair => {
      matrix[pair.llmAssessment][pair.userAssessment]++;
    });

    const correct = filteredPairs.filter(pair => 
      pair.llmAssessment === pair.userAssessment
    ).length;

    return {
      matrix,
      total: filteredPairs.length,
      correct
    };
  };

  const primaryData = useMemo(() => 
    getMatrixData(primaryRange), 
    [assessmentPairs, primaryRange]
  );

  const comparisonData = useMemo(() => 
    comparisonRange ? getMatrixData(comparisonRange) : null,
    [assessmentPairs, comparisonRange]
  );

  return (
    <Box sx={{ padding: 3 }} alignItems="center">
        <Typography variant="h5">
          Confusion Matrix
          <Tooltip 
              sx={{ padding: 0, marginLeft: 1 }} 
              title={
                <Typography sx={{ margin: 0, padding: 0}}>
                  Die Bewertungen des LLMs werden der Korrektur des Anwalts engegengesetzt.<br />
                  Die Diagonale zeigt die korrekten Bewertungen an. Die Werte außerhalb der Diagonale die falschen Bewertungen des LLMs.
                </Typography>
              } 
              componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: 'common.white',
                    color: 'text.primary',
                    boxShadow: 2,
                  },
                },
              }}
            >
              <IconButton>
                <HelpOutlineOutlined />
              </IconButton>
            </Tooltip>
        </Typography>
        <Stack spacing={3} marginTop={3}>
          <Grid container alignItems="center" gap="8px" flexWrap="nowrap">
            <Grid item md={comparisonRange ? 6 : 11}>
              <FormControl fullWidth>
                <Select
                  value={primaryRangeIndex}
                  onChange={(e) => setPrimaryRangeIndex(e.target.value as number)}
                >
                  {timeRangeOptions.map((option, index) => (
                    <MenuItem key={option.label} value={index}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {comparisonRange ? (
              <Grid item md={comparisonRange ? 6 : 11}>
                <Stack direction="row" spacing={1} alignItems="center">
                  <FormControl fullWidth>
                    <Select
                      value={comparisonRangeIndex}
                      onChange={(e) => setComparisonRangeIndex(e.target.value as number)}
                    >
                      {timeRangeOptions.map((option, index) => (
                        <MenuItem key={option.label} value={index}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <IconButton 
                    onClick={() => setComparisonRangeIndex(null)}
                  >
                    <CloseIcon />
                  </IconButton>
                </Stack>
              </Grid>
            ) : (
              <Grid item md={1}>
                <Button 
                  variant="outlined" 
                  fullWidth
                  onClick={() => setComparisonRangeIndex(1)}
                >
                  <AddIcon/>
                </Button>
              </Grid>
            )}
          </Grid>

          <Grid container gap="8px" flexWrap="nowrap">
            <Grid item xs={12} md={comparisonRange ? 6 : 12}>
              <MatrixTable 
                matrixData={primaryData.matrix}
                total={primaryData.total}
                correct={primaryData.correct}
                timeRange={primaryRange}
              />
            </Grid>
            {comparisonData && (
            <Grid item xs={12} md={6}>
              <MatrixTable 
                matrixData={comparisonData.matrix}
                total={comparisonData.total}
                correct={comparisonData.correct}
                timeRange={comparisonRange}
              />
            </Grid>
            )}
        </Grid>
      </Stack>
    </Box>
  );
};

export default ConfusionMatrix;