export enum ContractState {
    // WAITING_FOR_OCR = 'WAITING_FOR_OCR', 
    // WAITING_FOR_EXTERNAL_CLASSIFICATION = 'WAITING_FOR_EXTERNAL_CLASSIFICATION', 
    // CLASSIFICATION = 'CLASSIFICATION', 
    // ALTERNATIVE_FORMULATIONS = 'ALTERNATIVE_FORMULATIONS', 
    // FINISHED = 'FINISHED',
    // Contract is created
    CREATED = 'CREATED',

    // PDF is uploaded
    PDF_UPLOADED = 'PDF_UPLOADED',

    // OCR is running, Enhanced OCR is running
    OCR_RUNNING = 'OCR_RUNNING',
    
    // OCR is finished and external classification is running
    EXTERNAL_CLASSIFICATION_RUNNING = 'EXTERNAL_CLASSIFICATION_RUNNING',

    // OCR is finished and external classification is done
    EXTERNAL_CLASSIFICATION_DONE = 'EXTERNAL_CLASSIFICATION_DONE',

    // Lawyers review the contract
    INTERNAL_CLASSIFICATION = 'INTERNAL_CLASSIFICATION',

    // Lawyer finished reviewing
    FINISHED_REVIEW = 'FINISHED_REVIEW',

    // At least one alternative formulation is generated
    ALTERNATIVE_FORMULATIONS = 'ALTERNATIVE_FORMULATIONS',

    // Contract is finished => maybe create button "finished" and then modal pops up with "are you sure?"
    FINISHED = 'FINISHED'
}

export interface Contract {
    _id: string
    title: string
    description: string
    file: string
    state: ContractState
    owner: string
    industry: string
    createdAt: string
    updatedAt: string
    pageWidths: number[]
    pageHeights: number[]
}

export interface ContractSliceInterface {
    filteredAssessment: boolean[]
    selectedContract: Contract | null
}
