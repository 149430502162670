import React, { useMemo } from 'react';
import { 
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Tooltip,
  IconButton
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AnalysisObject } from '../../features/Analysis/types';
import { blue } from '@mui/material/colors';
import { HelpOutlineOutlined } from '@mui/icons-material';

const ConfidenceScore = ({ analysisData }: { analysisData: Partial<AnalysisObject>[] }) => {
  const { gridData, ruleMapping, maxVersion, ruleVersionTexts } = useMemo(() => {
    // Process data to create grid
    const rulesMap = new Map();
    const ruleVersionTextsMap = new Map<string, Map<number, string>>();
    let maxVer = 0;
    const ruleMappingTemp: { id: string; text: any; }[] = [];

    analysisData.forEach(analysis => {
      analysis?.clauses?.forEach(clause => {
        clause.llmReviews.forEach(review => {
          review.appliedRules.forEach(rule => {
            if (!rulesMap.has(rule.ruleId)) {
              rulesMap.set(rule.ruleId, new Map());
              ruleMappingTemp.push({ 
                id: `Prüfanleitung #${ruleMappingTemp.length + 1}`, 
                text: rule.ruleText 
              });
              ruleVersionTextsMap.set(rule.ruleId, new Map());
            }
            
            // Store version-specific rule text
            if (!ruleVersionTextsMap.get(rule.ruleId)?.has(rule.version)) {
              ruleVersionTextsMap.get(rule.ruleId)?.set(rule.version, rule.ruleText);
            }
            
            if (!rulesMap.get(rule.ruleId).has(rule.version)) {
              rulesMap.get(rule.ruleId).set(rule.version, []);
            }

            rulesMap.get(rule.ruleId).get(rule.version).push(review.confidence);
            maxVer = Math.max(maxVer, rule.version);
          });
        });
      });
    });

    // Convert to grid data
    const grid = Array.from(rulesMap.entries()).map(([ruleId, versions]) => {
      const versionConfidences: { [key: number]: number | null } = {};
      for (let v = 1; v <= maxVer; v++) {
        const confidences = versions.get(v) || [];
        versionConfidences[v] = confidences.length 
          ? confidences.reduce((a: any, b: any) => a + b, 0) / confidences.length 
          : null;
      }
      return { ruleId, confidences: versionConfidences };
    });

    return { 
      gridData: grid, 
      ruleMapping: ruleMappingTemp,
      maxVersion: maxVer,
      ruleVersionTexts: ruleVersionTextsMap
    };
  }, [analysisData]);

  // Function to get color based on confidence score remains the same
  const getColor = (score: any) => {
    if (score === null) return '#f5f5f5';
    const intensity = Math.floor((score / 100) * 4);
    switch (intensity) {
      case 0: return blue[50];
      case 1: return blue[200];
      case 2: return blue[400];
      case 3: return blue[600];
      default: return blue[900];
    }
  };

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h5" gutterBottom>
        Confidence Score der Prüfanleitungen
        <Tooltip 
          sx={{ padding: 0, marginLeft: 1 }} 
          title={
            <Typography sx={{ margin: 0, padding: 0}}>
              <b>Wie gut kann das LLM die Prüfanleitungen anwenden?</b> <b>Ein hoher Wert könnte bedeuten, dass die Prüfanleitung gut formuliert ist.</b> <br />
              <br />
              Die X-Achse zeigt die Regeln. (R...) <br />
              Die Y-Achse die Versionen der Regel. (v...) 
            </Typography>
          } 
          componentsProps={{
            tooltip: {
              sx: {
                bgcolor: 'common.white',
                color: 'text.primary',
                boxShadow: 2,
              },
            },
          }}
        >
          <IconButton>
            <HelpOutlineOutlined />
          </IconButton>
        </Tooltip>
      </Typography>

      {/* Visualization Container */}
      <Box sx={{ 
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        py: 4
      }}>
        {/* Main Visualization Area */}
        <Box sx={{ 
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%'
        }}>
          {/* X-axis Label */}
          <Typography 
            variant="body2" 
            color="text.secondary"
            sx={{ mb: 2 }}
          >
            Prüfanleitungen
          </Typography>

          {/* Grid Container */}
          <Box sx={{ 
            display: 'flex',
            alignItems: 'center',
            gap: 2
          }}>
            {/* Y-axis Label */}
            <Typography 
              variant="body2" 
              color="text.secondary"
              sx={{ 
                transform: 'rotate(-90deg)',
                whiteSpace: 'nowrap',
                marginLeft: '-120px'
              }}
            >
              Version der Prüfanleitungen
            </Typography>

            {/* Version Labels and Grid */}
            <Box sx={{ display: 'flex', m: 4, alignItems: 'center', ml: '-50px' }}>
              <Box sx={{ pr: 2, pt: 2, textAlign: 'right' }}>
                {Array.from({ length: maxVersion }, (_, i) => (
                  <Typography 
                    key={`version-${i}`} 
                    variant="caption" 
                    sx={{ 
                      display: 'block', 
                      height: 32, 
                      lineHeight: '32px',
                      color: 'text.secondary' 
                    }}
                  >
                    v{maxVersion - i}
                  </Typography>
                ))}
              </Box>
              
              {/* Grid */}
              <Box sx={{ flex: 1, overflowX: 'auto' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  {/* Rule Labels */}
                  <Box sx={{ display: 'flex', ml: 1 }}>
                    {ruleMapping.map((_, i) => (
                      <Typography
                        key={`rule-${i}`}
                        variant="caption"
                        sx={{
                          width: 32,
                          transform: 'rotate(-20deg)',
                          transformOrigin: 'bottom left',
                          whiteSpace: 'nowrap',
                          color: 'text.secondary',
                          m: '1px'
                        }}
                      >
                        {`P${i + 1}`}
                      </Typography>
                    ))}
                  </Box>
                  
                  {/* Confidence Squares */}
                  {Array.from({ length: maxVersion }, (_, versionIndex) => (
                    <Box key={`row-${versionIndex}`} sx={{ display: 'flex' }}>
                      {gridData.map((ruleData, ruleIndex) => {
                        const version = maxVersion - versionIndex;
                        const confidence = (ruleData.confidences[version] ?? 0) * 100;
                        const ruleText = ruleVersionTexts.get(ruleData.ruleId)?.get(version) || 'No Rule Found#1';
                        
                        return (
                          <Tooltip 
                            key={`cell-${ruleIndex}-${version}`}
                            title={
                              <div style={{ whiteSpace: 'pre-wrap' }}>
                                {`${ruleText === 'No Rule Found#1' ? 'Diese Version der Prüfanleitung gibt es nicht oder wurde noch nicht angewendet' : `P${ruleIndex + 1}, v${version}\n${ruleText}\nConfidence: ${confidence.toFixed(1)}%`}`}
                              </div>
                            }
                          >
                            <Box
                              sx={{
                                width: 32,
                                height: 32,
                                m: '1px',
                                backgroundColor: getColor(confidence),
                                borderRadius: 0.5,
                                transition: 'opacity 0.2s',
                                '&:hover': {
                                  opacity: 0.7
                                }
                              }}
                            />
                          </Tooltip>
                        );
                      })}
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        {/* Legend */}
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          gap: 1, 
          mt: 4,
          ml: '-30px'
        }}>
          <Typography variant="caption" color="text.secondary">
            Niedrig
          </Typography>
          {[blue[50], blue[200], blue[400], blue[600], blue[900]].map((color) => (
            <Box
              key={color}
              sx={{
                width: 16,
                height: 16,
                backgroundColor: color,
                borderRadius: 0.5
              }}
            />
          ))}
          <Typography variant="caption" color="text.secondary">
            Hoch
          </Typography>
        </Box>
      </Box>

      {/* Rule Details Accordion */}
      <Accordion elevation={0}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Alle aktuellen angewandten Prüfanleitungen</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Index</TableCell>
                  <TableCell>Letzter Stand</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {ruleMapping.map((rule, index) => (
                  <TableRow key={rule.id}>
                    <TableCell>P{index + 1}</TableCell>
                    <TableCell>{rule.text}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default ConfidenceScore;