import {Box, Button, Card, Typography} from '@mui/material';
import React from 'react';
import {useDispatch} from 'react-redux';
import {Assessment} from '../../../features/Clause/types';
import {useGetParagraphsAndClausesQuery} from '../../../features/Contract/ContractApiSlice';
import {setFilteredAssessment} from '../../../features/Contract/ContractSlice';
import {type Paragraph} from '../../../features/Paragraph/types';
import {useAppSelector} from '../../../hooks/typedReduxHooks';

interface ClassificationInfoProps {
    contractId: string
}

const ClassificationInfo: React.FC<ClassificationInfoProps> = ({contractId}): any => {
    const dispatch = useDispatch();

    const {data: paragraphs = []} = useGetParagraphsAndClausesQuery(contractId);

    const assessmentCounts = {
        [Assessment.VALID]: 0,
        [Assessment.UNFAIR]: 0,
        [Assessment.VOID]: 0,
        [Assessment.TOREASSESS]: 0
    };

    const assessmentTitle = ['In Ordnung', 'Unfair', 'Unzulässig', 'Neubewertung'];

    const filteredAssessment: boolean[] = useAppSelector((state) => state.contract.filteredAssessment);

    paragraphs.forEach(paragraph => {
        paragraph.clauses.forEach(clause => {
            const latestReview = clause.latestReview;
            const assessment = latestReview?.assessment;
            if (!clause.changed && assessment !== undefined) {
                assessmentCounts[assessment]++;
            }
        
        });
    });

    return (
        <Card sx={{
            marginLeft: 1,
            p: 1.5,
            width: '100%'
        }}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-around',
                width: '100%'
            }}>
                <Typography style={{
                    fontWeight: 'bold'
                }}></Typography> {[Assessment.VALID, Assessment.UNFAIR, Assessment.VOID, Assessment.TOREASSESS].map((assessmentType, index) => (
                <Button key={assessmentType} sx={{
                    // backgroundColor: 'tertiary.main',
                    flex: '1 1 auto',
                    mr: 1,
                    ml: 1,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    p: 0.5,
                    borderWidth: filteredAssessment[index] ? 1 : 1,
                    borderStyle: 'solid',
                    borderColor: filteredAssessment[index] ? 'primary.main' : 'lightgrey',
                    transition: 'transform 0.3s ease',
                    '&:hover': {
                        transform: 'scale(1.05)'
                    }
                }} style={{
                    borderRadius: 10
                }} onClick={() => {
                    const newFilteredAssessment = filteredAssessment.map((item, currentIndex) => currentIndex === index ? !item : item);
                    dispatch(setFilteredAssessment(newFilteredAssessment));
                }}>
                    <Typography variant="body2" sx={{
                        fontWeight: 'bold',
                        width: '80%',
                        ml: 1
                    }}>
                        {assessmentTitle[index]}
                    </Typography>
                    <Card sx={{
                        backgroundColor: `assessment.${assessmentType.toLowerCase()}`,
                        width: '20%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '4px 8px'
                    }} style={{
                        borderRadius: 8
                    }}>
                        <Typography sx={{
                            color: 'white',
                            fontWeight: 'bold'
                        }}>
                            {assessmentCounts[assessmentType]}
                        </Typography>
                    </Card>
                </Button>))}
            </Box>

        </Card>);
};

export default ClassificationInfo;
