import React from 'react';
import {Box, Button, Menu, MenuItem, type MenuProps, Typography} from '@mui/material';
import {alpha, styled} from '@mui/material/styles';
import {useParams} from 'react-router-dom';
import {AlignmentType, Document, HeadingLevel, Packer, Paragraph, TabStopPosition, TabStopType, TextRun} from 'docx';
import FileSaver, {saveAs} from 'file-saver';
import {useGetContractWithAlternativesQuery} from '../../features/Alternative/AlternativeApiSlice';
import {useGetContractByIdQuery, useUpdateContractMutation} from '../../features/Contract/ContractApiSlice';
import LoadingSpinner from '../general/LoadingSpinner';
import GetAppIcon from '@mui/icons-material/GetApp';
import DataObjectIcon from '@mui/icons-material/DataObject';
import DescriptionIcon from '@mui/icons-material/Description';
import {ContractState} from '../../features/Contract/types';

const StyledMenu = styled((props: MenuProps) => (
    <Menu elevation={0} anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
    }} transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
    }}{...props}/>))(({theme}) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow: 'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0'
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5)
            },
            '&:active': {
                backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity)
            }
        }
    }
}));

const DownloadContractButton = (): any => {
    const {_id} = useParams();
    const {
        data: contract,
        isLoading,
        isError
    } = useGetContractWithAlternativesQuery(_id ?? '');
    const {data: contractInfo} = useGetContractByIdQuery(_id ?? '');
    const [updateContract] = useUpdateContractMutation();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (): void => {
        setAnchorEl(null);
    };

    let content: JSX.Element | null = null;

    if (isLoading) {
        content =
            <LoadingSpinner/>;
    } else if (isError) {
        content =
            <Typography>Herunterladen nicht möglich</Typography>;
    } else {
        content =
            <Typography variant="body1">Vertrag herunterladen</Typography>;
    }

    const createContractDocx = (): void => {
        if (contract) {
            const paragraphsInSection = contract.slice().sort((a, b) => a.position - b.position).map((paragraph) => [new Paragraph({
                children: [new TextRun(paragraph.content)],
                heading: HeadingLevel.HEADING_2,
                spacing: {after: 200}
            }), ...paragraph.clauses.slice().sort((a, b) => a.position - b.position).map((clause) => (new Paragraph({
                children: [new TextRun({
                    text: clause.content,
                    size: 24
                })],
                spacing: {after: 200}
            })))]).flat(); // Use flat() to flatten the nested arrays

            const doc = new Document({
                sections: [{
                    properties: {},
                    children: [// Title
                        new Paragraph({
                            children: [new TextRun('Arbeitsvertrag')],
                            heading: HeadingLevel.TITLE,
                            alignment: AlignmentType.CENTER,
                            spacing: {after: 200}
                        }),

                        // Introduction
                        new Paragraph({
                            children: [new TextRun({
                                text: 'Der Arbeitsvertrag wird geschlossen zwischen',
                                size: 24
                            })],
                            spacing: {after: 100}
                        }), new Paragraph({
                            children: [new TextRun({
                                text: '..................',
                                size: 24
                            })],
                            spacing: {after: 200}
                        }), new Paragraph({
                            children: [new TextRun({
                                text: '- nachfolgend "Arbeitgeber" genannt -',
                                size: 24
                            })],
                            alignment: AlignmentType.END,
                            spacing: {after: 200}
                        }), new Paragraph({
                            children: [new TextRun({
                                text: 'und',
                                size: 24
                            })],
                            spacing: {after: 100}
                        }), new Paragraph({
                            children: [new TextRun({
                                text: '..................',
                                size: 24
                            })],
                            spacing: {after: 200}
                        }), new Paragraph({
                            children: [new TextRun({
                                text: '- nachfolgend "Arbeitnehmer" genannt -',
                                size: 24
                            })],
                            alignment: AlignmentType.END,
                            spacing: {after: 200}
                        }), // Content
                        ...paragraphsInSection, new Paragraph({
                            children: [new TextRun({
                                text: '....................................................',
                                size: 24
                            })],
                            spacing: {before: 600}
                        }), new Paragraph({
                            children: [new TextRun({
                                text: 'Ort, Datum',
                                size: 24
                            })]
                        }), new Paragraph({
                            tabStops: [{
                                type: TabStopType.RIGHT,
                                position: TabStopPosition.MAX
                            }],
                            children: [new TextRun({
                                text: '....................................................',
                                size: 24
                            }), new TextRun({
                                text: '\t....................................................',
                                size: 24
                            })],
                            spacing: {before: 600}
                        }), new Paragraph({
                            tabStops: [{
                                type: TabStopType.RIGHT,
                                position: TabStopPosition.MAX
                            }],
                            children: [new TextRun({
                                text: 'Unterschrift Arbeitgeber',
                                size: 24
                            }), new TextRun({
                                text: '\tUnterschrift Arbeitnehmer',
                                size: 24
                            })]
                        })]
                }]
            });

            Packer.toBlob(doc).then(async blob => {
                saveAs(blob, `${contractInfo?.title}.docx`);
                await updateContract({
                    contractId: contractInfo?._id ?? '',
                    requestBody: {state: ContractState.FINISHED}
                });
            }).catch((error) => {
                console.log(error);
            });
        }
    };

    const createContractJson = (): void => {
        const blob = new Blob([JSON.stringify(contract)], {type: 'application/json'});
        FileSaver.saveAs(blob, `${contractInfo?.title}.json`);
        updateContract({
            contractId: contractInfo?._id ?? '',
            requestBody: {state: ContractState.FINISHED}
        }).catch((err) => {
            console.log(err);
        });
    };

    return (
        <Box>
            <Button id="demo-customized-button" aria-controls={open ? 'demo-customized-menu' : undefined} aria-haspopup="true" aria-expanded={open ? 'true' : undefined} variant="contained" disableElevation onClick={handleClick} endIcon={
                <GetAppIcon/>}>
                {content}
            </Button>
            <StyledMenu id="demo-customized-menu" MenuListProps={{
                'aria-labelledby': 'demo-customized-button'
            }} anchorEl={anchorEl} open={open} onClose={handleClose}>
                <MenuItem onClick={createContractDocx} disableRipple>
                    <DescriptionIcon/> Vertrag als DOCX herunterladen
                </MenuItem>
                <MenuItem onClick={createContractJson} disableRipple>
                    <DataObjectIcon/> Vertrag als JSON herunterladen
                </MenuItem>
            </StyledMenu>
        </Box>);
};

export default DownloadContractButton;
