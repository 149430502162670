import React, {useState} from 'react';
import {Avatar, Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from '@mui/material';
import {useDeleteUserMutation, useGetAllUsersQuery} from '../../features/User/UserApiSlice';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import FormDialog from '../general/FormDialog';
import UpdateUserForm from './UpdateUserForm';
import {type IUser} from '../../features/User/types';

const UserTable: React.FC<any> = () => {
    const {data: users} = useGetAllUsersQuery();
    const [deleteUser] = useDeleteUserMutation();

    const [open, setOpen] = useState(false);

    const handleClickOpen = (): void => {
        setOpen(true);
    };

    const handleClose = (): void => {
        setOpen(false);
    };

    const [updateUser, setUpdateUser] = useState<IUser | undefined>(undefined);

    return (<>
        <FormDialog open={open} handleClose={handleClose}>
            <UpdateUserForm user={updateUser} handleClose={handleClose}/>
        </FormDialog>
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Aktionen</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {users?.map((user) => (
                        <TableRow key={user._id}>
                            <TableCell>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                    <Avatar>{`${user.firstName.charAt(0)}${user.lastName.charAt(0)}`}</Avatar>
                                    <Typography ml={1}>{`${user.firstName} ${user.lastName}`}</Typography>
                                </Box>
                            </TableCell>
                            <TableCell>{user.email}</TableCell>
                            <TableCell>
                                <Box sx={{display: 'flex'}}>
                                    <Box mr={1}>
                                        <Button variant="outlined" color="primary" startIcon={
                                            <EditIcon/>} onClick={() => {
                                            setUpdateUser(user);
                                            handleClickOpen();
                                        }}> Ändern </Button>
                                    </Box>
                                    <Button variant="outlined" color="primary" startIcon={
                                        <DeleteIcon/>} onClick={(e) => {
                                        deleteUser(user._id).catch((error) => {
                                            console.log(error);
                                        });
                                    }}> Löschen </Button>
                                </Box>
                                <Box> </Box>
                            </TableCell>
                        </TableRow>))}
                </TableBody>
            </Table>
        </TableContainer>
    </>);
};

export default UserTable;
