import {apiSlice} from '../../app/api/apiSlice';
import {type Assessment, type Clause} from './types'; // Definieren Sie Ihren Clause-Typ

export const ClauseApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getClausesByContract: builder.query<Clause[], string>({
            query: (contractId) => `/clauses/byContract/${contractId}`,
            providesTags: ['Clause']
        }),
        createClause: builder.mutation<Clause, FormData>({
            query: (formData) => ({
                url: `/clauses`,
                method: 'POST',
                body: formData
            }),
            invalidatesTags: ['Clause']
        }),
        getClauseById: builder.query<Clause, string>({
            query: (clauseId) => `/clauses/${clauseId}`,
            providesTags: (result) => result != null ? [{
                type: 'Clause',
                id: result._id
            }] : []
        }),
        updateClause: builder.mutation<any, { clauseId: string, requestBody: { content?: string, assessmentUser?: Assessment } }>({
            query: ({
                clauseId,
                requestBody
            }) => ({
                url: `/clauses/${clauseId}`,
                method: 'PUT',
                body: requestBody
            }),
            invalidatesTags: ['Clause']
        }),
        deleteClause: builder.mutation<{ message: string }, string>({
            query: (clauseId) => ({
                url: `/clauses/${clauseId}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['Clause']
        }),
        upgradeClause: builder.mutation<any, string>({
            query: (clauseId) => ({
                url: `/clauses/upgrade/${clauseId}`,
                method: 'PATCH'
            }),
            invalidatesTags: ['Clause']
        }),
        mergeClause: builder.mutation<any, string>({
            query: (clauseId) => ({
                url: `/clauses/merge/${clauseId}`,
                method: 'PATCH'
            }),
            invalidatesTags: ['Clause']
        })
    })
});

export const {
    useGetClausesByContractQuery,
    useCreateClauseMutation,
    useGetClauseByIdQuery,
    useUpdateClauseMutation,
    useDeleteClauseMutation,
    useUpgradeClauseMutation,
    useMergeClauseMutation
} = ClauseApiSlice;

export const {
    endpoints: {
        getClausesByContract,
        getClauseById,
        createClause,
        updateClause,
        deleteClause,
        upgradeClause,
        mergeClause
    }
} = ClauseApiSlice;
