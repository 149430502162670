// import UpdateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {IconButton, ListItemIcon, ListItemText, Menu, MenuItem} from '@mui/material';
import React, {useState} from 'react';
import {useDeleteContractMutation, useResetContractMutation, useUpdateContractMutation} from '../../features/Contract/ContractApiSlice';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import {Contract, ContractState} from '../../features/Contract/types';
import CachedIcon from '@mui/icons-material/Cached';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

interface Props {
    contract: Contract;
}

export default function OptionsMenu({ contract }: Props) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [resetAnchorEl, setResetAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const resetOpen = Boolean(resetAnchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(null);
        setResetAnchorEl(null);
    };

    const handleResetClick = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setResetAnchorEl(event.currentTarget);
    };

    const [deleteContract] = useDeleteContractMutation();
    const [resetContract] = useResetContractMutation();
    const [updateContract] = useUpdateContractMutation();

    async function downloadContractPDF() {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/contracts/files/${contract?.file}`, {
                credentials: 'include',
                mode: 'cors'
            });

            if (!response.ok) {
                throw new Error(response.statusText);
            }

            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = contract.title;
            document.body.appendChild(a);
            a.click();
            a.remove();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Failed to download PDF:', error);
        }
    }

    async function handleReset(targetState: ContractState) {
        try {
            await resetContract({ contractId: contract._id, targetState }).unwrap();
        } catch (error) {
            console.error('Failed to reset contract:', error);
        }
        handleClose({ stopPropagation: () => {} } as React.MouseEvent<HTMLElement>);
    }

    async function handleUpdateContract(state: ContractState) {
        try {
            await updateContract({
                contractId: contract._id,
                requestBody: { state }
            }).unwrap();
        } catch (error) {
            console.error('Failed to update contract:', error);
        }
        handleClose({ stopPropagation: () => {} } as React.MouseEvent<HTMLElement>);
    }

    const isResetDisabled = contract?.state === ContractState.OCR_RUNNING || contract?.state === ContractState.EXTERNAL_CLASSIFICATION_RUNNING;

    return (
        <>
            <IconButton
                id="options-button"
                aria-controls={open ? 'options-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="options-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{ 'aria-labelledby': 'options-button' }}
            >
                {!isResetDisabled && (
                    <MenuItem onClick={(e) => {
                        e.stopPropagation();
                        deleteContract(contract._id).catch((error) => {
                            console.log(error);
                        });
                    }}>
                        <ListItemIcon>
                            <DeleteIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Delete</ListItemText>
                    </MenuItem>
                )}
                <MenuItem onClick={handleResetClick}>
                    <ListItemIcon>
                        <CachedIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Reset</ListItemText>
                    <ArrowRightIcon fontSize="small" />
                </MenuItem>
                <MenuItem onClick={(e) => {
                    e.stopPropagation();
                    downloadContractPDF();
                }}>
                    <ListItemIcon>
                        <FileDownloadIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Download</ListItemText>
                </MenuItem>
            </Menu>
            <Menu
                id="reset-menu"
                anchorEl={resetAnchorEl}
                open={resetOpen}
                onClose={handleClose}
                MenuListProps={{ 'aria-labelledby': 'reset-button' }}
            >
                <MenuItem onClick={(e) => {e.stopPropagation(); handleReset(ContractState.PDF_UPLOADED); window.location.reload();}}>
                    <ListItemText>Zurücksetzen auf Beginn</ListItemText>
                </MenuItem>
                <MenuItem onClick={(e) => {e.stopPropagation(); handleReset(ContractState.EXTERNAL_CLASSIFICATION_RUNNING); window.location.reload();}}>
                    <ListItemText>AI Klassifizierung neu starten</ListItemText>
                </MenuItem>
                <MenuItem onClick={(e) => {e.stopPropagation(); handleReset(ContractState.EXTERNAL_CLASSIFICATION_DONE); window.location.reload();}}>
                    <ListItemText>Zurück zu AI Klassifizierung</ListItemText>
                </MenuItem>
                {(contract.state === ContractState.ALTERNATIVE_FORMULATIONS || contract.state === ContractState.FINISHED || contract.state == ContractState.FINISHED_REVIEW) && (
                <MenuItem onClick={(e) => {e.stopPropagation(); handleUpdateContract(ContractState.INTERNAL_CLASSIFICATION); window.location.reload();}}>
                    <ListItemText>Nicht anwaltlich abgenommen</ListItemText>
                </MenuItem>
                )}
                {(contract.state === ContractState.FINISHED) && (
                <MenuItem onClick={(e) => {e.stopPropagation(); handleReset(ContractState.ALTERNATIVE_FORMULATIONS); window.location.reload();}}>
                    <ListItemText>Zurück zu Alternativen auswählen</ListItemText>
                </MenuItem>
                )}
                {(contract.state === ContractState.FINISHED) && (
                <MenuItem onClick={(e) => {e.stopPropagation(); handleUpdateContract(ContractState.ALTERNATIVE_FORMULATIONS); window.location.reload();}}>
                    <ListItemText>Noch nicht abgeschlossen</ListItemText>
                </MenuItem>
                )}
            </Menu>
        </>
    );
}