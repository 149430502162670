import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import type {RootState} from '../../app/store';
import type {ParagraphSliceInterface} from './types';

const initialState: ParagraphSliceInterface = {
    selectedParagraphId: '',
    editParagraphId: '',
    newParagraphText: ''
};

export const ParagraphSlice = createSlice({
    name: 'paragraph',
    initialState,
    reducers: {
        setSelectedParagraphId: (state, action: PayloadAction<string>) => {
            state.selectedParagraphId = action.payload;
            return state;
        },
        setEditParagraphId: (state, action: PayloadAction<string>) => {
            state.editParagraphId = action.payload;
            return state;
        },
        setNewParagraphText: (state, action: PayloadAction<string>) => {
            state.newParagraphText = action.payload;
            return state;
        }
    }
});

export const {
    setSelectedParagraphId,
    setEditParagraphId,
    setNewParagraphText
} = ParagraphSlice.actions;

export const paragraph = (state: RootState): ParagraphSliceInterface => state.paragraph;

export default ParagraphSlice.reducer;
