import React, { useState, useEffect } from 'react';
import { Box, Button, Card, Typography, Menu, MenuItem } from '@mui/material';
import CachedIcon from '@mui/icons-material/Cached';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useClassifyAllClausesMutation, useGetParagraphsAndClausesQuery, useResetContractMutation, useUpdateContractMutation } from '../../../features/Contract/ContractApiSlice';
import { type Paragraph } from '../../../features/Paragraph/types';
import { ContractState } from '../../../features/Contract/types';
import ConfirmActionDialog from '../../general/ConfirmActionDialog';

interface ClassificationInfoProps {
    contractId: string,
    contractState: ContractState
}

const ClassificationAndChangeReset: React.FC<ClassificationInfoProps> = ({ contractId, contractState }) => {
    const { data: paragraphs = [] } = useGetParagraphsAndClausesQuery(contractId);

    /* METHODS FOR API-REQUESTS */
    const [classifyAllClauses] = useClassifyAllClausesMutation();
    const [resetContract] = useResetContractMutation();
    const [updateContract] = useUpdateContractMutation();

    /* STATES FOR DIALOG WINDOW */
    const [openDialog, setOpenDialog] = useState(false);
    const [actionToConfirm, setActionToConfirm] = useState<() => void>(() => {});
    const [resetAnchorEl, setResetAnchorEl] = useState<null | HTMLElement>(null);
    const [isContractFinished, setIsContractFinished] = useState<boolean>(false);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);

    /* CHECK IF "NEULADEN"-BUTTON SHOULD BE ENABLED */
    const hasChanges = checkForChangedClauses(paragraphs);
    
    useEffect(() => {
        setIsContractFinished(
            contractState === ContractState.FINISHED || 
            contractState === ContractState.FINISHED_REVIEW || 
            contractState === ContractState.ALTERNATIVE_FORMULATIONS
        );
    }, [contractState]);

    function checkForChangedClauses(paragraphs: Paragraph[]): boolean {
        const hasChanges = paragraphs.some(paragraph => 
            paragraph.clauses.some(clause => clause.changed)
        );

        // if hasChanges => set contract state to alternative formulations
        if (hasChanges && contractState === ContractState.FINISHED) {
            resetContract({ contractId, targetState: ContractState.ALTERNATIVE_FORMULATIONS });
        }

        return hasChanges;
    }

    /* RELOAD AI CLASSIFICATIONS */
    const reloadClassifications = async (): Promise<void> => {
        try {
            await classifyAllClauses(contractId);
        } catch (error) {
            console.error('Failed to reload classifications:', error);
        } finally {
            window.location.reload();
        }
    };

    const handleReset = async (targetState: ContractState): Promise<void> => {
        if (isProcessing) return;

        setIsProcessing(true);
        try {
            await resetContract({ contractId, targetState }).unwrap();
            
            if (targetState === ContractState.PDF_UPLOADED || targetState === ContractState.EXTERNAL_CLASSIFICATION_RUNNING || targetState === ContractState.EXTERNAL_CLASSIFICATION_DONE || targetState === ContractState.INTERNAL_CLASSIFICATION) {
                setIsContractFinished(false);
            }
        } catch (error) {
            console.error('Failed to reset contract:', error);
        } finally {
            setIsProcessing(false);
            window.location.reload();
        }
    };

    const handleOpenDialog = (action: () => void): void => {
        setOpenDialog(true);
        setActionToConfirm(() => action);
    };

    const handleCloseDialog = (): void => {
        setOpenDialog(false);
        setActionToConfirm(() => {});
    };

    const handleResetClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setResetAnchorEl(event.currentTarget);
    };

    const handleResetClose = () => {
        setResetAnchorEl(null);
    };

    // finish contract -> so that it can be used in the analysis
    const handleFinishContract = async (): Promise<void> => {
        if (isProcessing) return;

        setIsProcessing(true);
        try {
            setIsContractFinished(true);
            await updateContract({
                contractId,
                requestBody: { state: ContractState.FINISHED_REVIEW }
            }).unwrap();
        } catch (error) {
            console.error('Failed to finish contract:', error);
        } finally {
            setIsProcessing(false);
        }
        window.location.reload();
    };

    const handleUpdateContract = async (state: ContractState): Promise<void> => {
        if (isProcessing) return;

        setIsProcessing(true);
        try {
            await updateContract({
                contractId,
                requestBody: { state }
            }).unwrap();
        } catch (error) {
            console.error('Failed to update contract:', error);
        } finally {
            setIsProcessing(false);
        }

        window.location.reload();
    }

    return (
        <Card sx={{ marginLeft: 1, p: 1.5, width: '100%' }}>
            <ConfirmActionDialog 
                openDialog={openDialog} 
                handleCloseDialog={handleCloseDialog} 
                actionToConfirm={actionToConfirm}
            />
            <Box display="flex" justifyContent="right" sx={{ width: '100%', gap: 1 }}>
                <Button
                    variant="contained"
                    onClick={handleResetClick}
                    endIcon={<ArrowDropDownIcon />}
                    disabled={isProcessing}
                >
                    <CachedIcon />
                    <Typography ml={2} variant="body2">
                        {'Zurücksetzen'}
                    </Typography>
                </Button>
                <Menu
                    anchorEl={resetAnchorEl}
                    open={Boolean(resetAnchorEl)}
                    onClose={handleResetClose}
                >
                    <MenuItem onClick={() => {
                        handleOpenDialog(() => handleReset(ContractState.PDF_UPLOADED));
                        handleResetClose();
                    }}>
                        Zurücksetzen auf Beginn
                    </MenuItem>
                    <MenuItem onClick={() => {
                        handleOpenDialog(() => handleReset(ContractState.EXTERNAL_CLASSIFICATION_RUNNING));
                        handleResetClose();
                    }}>
                        AI Klassifizierung neu starten
                    </MenuItem>
                    <MenuItem onClick={() => {
                        handleOpenDialog(() => handleReset(ContractState.EXTERNAL_CLASSIFICATION_DONE));
                        handleResetClose();
                    }}>
                        Zurück zu AI Klassifizierung
                    </MenuItem>
                    {(contractState === ContractState.ALTERNATIVE_FORMULATIONS || contractState === ContractState.FINISHED || contractState == ContractState.FINISHED_REVIEW) && (
                         <MenuItem onClick={() => {
                            handleOpenDialog(() => handleUpdateContract(ContractState.INTERNAL_CLASSIFICATION));
                            handleResetClose();
                        }}>
                            Nicht anwaltlich abgenommen
                        </MenuItem>
                    )}

                    {(contractState === ContractState.FINISHED) && (
                    <MenuItem onClick={() => {
                        handleOpenDialog(() => handleReset(ContractState.ALTERNATIVE_FORMULATIONS));
                        handleResetClose();
                    }}>
                        Zurück zu Alternativen auswählen
                    </MenuItem>
                    )}
                    {contractState === ContractState.FINISHED && (
                    <MenuItem onClick={() => {
                        handleOpenDialog(() => handleUpdateContract(ContractState.ALTERNATIVE_FORMULATIONS));
                        handleResetClose();
                    }}>
                        Noch nicht abgeschlossen
                    </MenuItem>
                    )}
                </Menu>
                <Button 
                    variant="contained" 
                    onClick={() => handleOpenDialog(reloadClassifications)} 
                    disabled={!hasChanges || isProcessing}
                >
                    <CachedIcon />
                    <Typography ml={2} variant="body2">
                        {'KI-Analyse'}
                    </Typography>
                </Button>
                <Button 
                    variant="contained" 
                    onClick={() => handleOpenDialog(handleFinishContract)}
                    color="success"
                    disabled={isContractFinished || isProcessing}
                >
                    <CheckCircleOutlineIcon />
                    <Typography ml={2} variant="body2">
                        {'Anwaltlich abgenommen'}
                    </Typography>
                </Button>
            </Box>
        </Card>
    );
};

export default ClassificationAndChangeReset;