import React, { useEffect, useRef, useMemo } from 'react';
import ApexCharts from 'apexcharts';
import { ApexOptions } from 'apexcharts';
import { Typography, Paper, Box, Tooltip, IconButton } from '@mui/material';
import { ContractMetrics } from '../../features/Analysis/types';
import { HelpOutlineOutlined } from '@mui/icons-material';

interface TimePerContractProps {
  metricsData: ContractMetrics[];
}

const TimePerContract: React.FC<TimePerContractProps> = ({ metricsData }) => {
  const chartRef = useRef<HTMLDivElement>(null);
  const chartInstance = useRef<ApexCharts | null>(null);

  const chartData = useMemo(() => {
    if (!metricsData) return [];

    // Group data by model
    const groupedByModel = metricsData.reduce((acc, metric) => {
      const modelName = metric.model;
      if (!acc[modelName]) {
        acc[modelName] = [];
      }
      acc[modelName].push(parseFloat(metric.timePer100Words.toFixed(2)));
      return acc;
    }, {} as Record<string, number[]>);

    // Transform into box plot data
    return Object.entries(groupedByModel).map(([model, times]) => {
      if (times.length >= 5) {
        // If enough data points, create normal box plot data
        return {
          x: model,
          y: times.sort((a, b) => a - b)
        };
      } else {
        // If fewer than 5 points, use their average as a single point
        const average = times.reduce((sum, time) => sum + time, 0) / times.length;
        return {
          x: model,
          y: [average, average, average, average, average] // Box plot needs 5 values (min, q1, median, q3, max)
        };
      }
    });
  }, [metricsData]);

  const options: ApexOptions = {
    chart: {
      defaultLocale: 'de',
      locales: [{
        name: 'de',
        options: {
          months: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
          shortMonths: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
          days: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
          shortDays: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa']
        }
      }],
      type: 'boxPlot',
      height: 350,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: true
      },
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 800,
      }
    },
    title: {
      text: 'Verarbeitungszeit pro 100 Wörter nach Modell',
      align: 'left',
      style: {
        fontSize: '16px',
        fontWeight: 'normal'
      }
    },
    plotOptions: {
      boxPlot: {
        colors: {
          upper: '#0d47a1',
          lower: '#1e88e5'
        }
      }
    },
    series: [{
      type: 'boxPlot',
      data: chartData
    }],
    xaxis: {
      title: {
        text: 'Model',
        style: {
          fontSize: '14px'
        }
      },
      labels: {
        style: {
          fontSize: '12px'
        }
      }
    },
    yaxis: {
      title: {
        text: 'Zeit in Sekunden',
        style: {
          fontSize: '14px'
        }
      },
      labels: {
        formatter: (value) => `${value.toFixed(2)}s`,
        style: {
          fontSize: '12px'
        }
      }
    },
    tooltip: {
      shared: false,
      intersect: true,
      custom: function({ seriesIndex, dataPointIndex, w }) {
        const series = w.config.series[seriesIndex];
        const data = series.data[dataPointIndex];
        const model = data.x;
        const times = data.y as number[];
        const min = times[0];
        const median = times[2];
        const max = times[4];

        return (
          `<div class="apexcharts-tooltip-title" style="padding: 2px">${model}</div>` +
          `<div style="padding: 2px">` +
            `<div style="padding-bottom: 2px"><b>Min:</b> ${min.toFixed(2)}s</div>` +
            `<div style="padding-bottom: 2px"><b>Median:</b> ${median.toFixed(2)}s</div>` +
            `<div><b>Max:</b> ${max.toFixed(2)}s</div>` + 
          `</div>`
        );
      }
    },
  };

  useEffect(() => {
    if (chartRef.current) {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }

      chartInstance.current = new ApexCharts(chartRef.current, options);
      chartInstance.current.render();
    }

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [options]);

  return (
    <Box sx={{ padding: 3 }} alignItems={"center"}>
      <Typography variant="h5" gutterBottom>
        Durchschnittliche Zeit pro Vertrag (LLM only)
        {/* Tooltip with explanation */}
        <Tooltip 
          sx={{ padding: 0, marginLeft: 1 }} 
          title={
            <Typography sx={{ margin: 0, padding: 0}}>
              {/* Anpassen */}
              <b>Wie lang benötigen die LLMs um 100 Wörter zu verarbeiten?</b> <br />
              Auf der X-Achse sieht man die unterschiedlichen Modelle. <br />
              Die Y-Achse zeigt die Zeit in Sekunden.
            </Typography>
          } 
          componentsProps={{
            tooltip: {
              sx: {
                bgcolor: 'common.white',
                color: 'text.primary',
                boxShadow: 2,
              },
            },
          }}
        >
          <IconButton>
            <HelpOutlineOutlined />
          </IconButton>
        </Tooltip>
      </Typography>
      <div ref={chartRef} />
      {/* <a href="/rules">Zu allen Regeln</a>  */}
      <Typography variant="caption" color="textSecondary" className="mt-2">
        * Nur vollständige Durchläufe berücksichtigt
        </Typography>
    </Box>
  );
};

export default TimePerContract;