import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Description from '@mui/icons-material/Description';
import {Autocomplete, Box, Button, CircularProgress, Paper} from '@mui/material';
import TextField from '@mui/material/TextField';
import * as React from 'react';
import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useCreateContractMutation} from '../../features/Contract/ContractApiSlice';
import FileUploadCard from './FileUploadCard';
import {industries} from '../../data/Industries';
import toast from 'react-hot-toast';

const UploadCard: React.FC = () => {
    const navigate = useNavigate();

    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [industry, setIndustry] = useState<string | null>(null);
    const [file, setFile] = useState<File | null>(null);
    const [uploadedFileName, setUploadedFileName] = useState<string | null>(null);
    const [isTitleValid, setIsTitleValid] = useState(true);
    const [isDescriptionValid, setIsDescriptionValid] = useState(true);
    const [isIndustryValid, setIsIndustryValid] = useState(true);

    const [createContract, {isLoading}] = useCreateContractMutation();

    const handleFileChange = (acceptedFiles: File[]): void => {
        if (acceptedFiles.length > 0) {
            setFile(acceptedFiles[0]);
            setUploadedFileName(acceptedFiles[0].name);
        }
    };

    const handleSubmit = async () => {
        if (!title) {
            setIsTitleValid(false);
            return;
        }
        setIsTitleValid(true);

        if (!description) {
            setIsDescriptionValid(false);
            return;
        }
        setIsDescriptionValid(true);

        if (!industry) {
            setIsIndustryValid(false);
            return;
        }
        setIsIndustryValid(true);

        if (!file) {
            toast.error('Bitte wählen Sie eine PDF Datei aus.');
            return;
        }

        const formData = new FormData();
        formData.append('title', title);
        formData.append('description', description);
        formData.append('industry', industry);
        formData.append('owner', '5fc9a1e9c2b52f88b4f5f700');
        formData.append('state', 'OCR');
        // Append other form fields

        if (file !== null) {
            formData.append('file', file);
        }

        try {
            const result = await createContract(formData);

            // Handle success (result) as needed
            if ('data' in result && '_id' in result.data) {
                //navigate(`/contracts/${result.data._id}`)
                toast.success("Der Vertrag wird jetzt bearbeitet. Das kann einige Minuten dauern.")
                setTitle("");
                setDescription("");
                setIndustry(null);
                setFile(null);
            }
        } catch (error) {
            // Handle error
            console.error('Error creating contract:', error);
        }

        setUploadedFileName(null);
    };

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            gap: 1,
            p: 1,
            flexGrow: 1
        }}>
            <TextField label="Titel" value={title} onChange={(e) => {
                setTitle(e.target.value);
            }} required error={!isTitleValid} helperText={!isTitleValid ? 'Titel erforderlich' : ''} fullWidth size="small" sx={{
                backgroundColor: 'background.default',
                "& fieldset": {
                    border: 'none'
                },
                borderRadius: '6px'
            }}/>
            <TextField label="Beschreibung" value={description} onChange={(e) => {
                setDescription(e.target.value);
            }} required error={!isDescriptionValid} helperText={!isDescriptionValid ? 'Beschreibung erforderlich' : ''} fullWidth size="small" sx={{
                backgroundColor: 'background.default',
                "& fieldset": {
                    border: 'none'
                },
                borderRadius: '6px'
            }}/>
            <Autocomplete options={industries} value={industry} onChange={(event, newValue) => setIndustry(newValue)} renderInput={(params) => (
                <TextField{...params} label="Branche" required error={!isIndustryValid} helperText={!isIndustryValid ? 'Branche erforderlich' : ''} fullWidth size="small" sx={{
                    backgroundColor: 'background.default',
                    "& fieldset": {
                        border: 'none'
                    },
                    borderRadius: '6px'
                }}/>)}/>
            <FileUploadCard handleFileChange={handleFileChange}/> {uploadedFileName && (
            <Box display="flex" alignItems="center">
                <Paper variant="outlined" style={{
                    padding: '8px',
                    display: 'flex',
                    alignItems: 'center',
                    borderStyle: 'dotted',
                    borderRadius: '8px'
                }}>
                    <Description style={{marginRight: '10px'}}/> {uploadedFileName}
                </Paper>
            </Box>)}
            <Button variant="contained" startIcon={!isLoading &&
                <CloudUploadIcon/>} disabled={isLoading} onClick={() => handleSubmit()} fullWidth sx={{
                mt: 1
            }}>
                {isLoading ?
                    <CircularProgress color="secondary"/> : 'Vertrag erstellen'}
            </Button>
        </Box>);
};

export default UploadCard;
