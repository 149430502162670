import {Box, Card, Grid} from '@mui/material';
import React from 'react';
import {useParams} from 'react-router-dom';
import {setSelectedClauseId} from '../../../features/Clause/ClauseSlice';
import {Assessment} from '../../../features/Clause/types';
import {useGetContractByIdQuery, useGetParagraphsAndClausesQuery} from '../../../features/Contract/ContractApiSlice';
import {type Paragraph} from '../../../features/Paragraph/types';
import {useAppDispatch, useAppSelector} from '../../../hooks/typedReduxHooks';
import ParagraphsAndClauses from '../classification/ParagraphsAndClauses';
import AlternativeFormulation from './AlternativeFormulation';
import LoadingSpinner from '../../general/LoadingSpinner';
import AlternativeInfo from './AlternativeInfo';
import AlternativeFilter from './AlternativeFilter';
import {useGetSelectedAlternativesByContractQuery} from '../../../features/Alternative/AlternativeApiSlice';
import {type IAlternative} from '../../../features/Alternative/types';

const AlternativePage = (): any => {
    const {_id} = useParams();
    const {
        data: contract,
        isLoading: isLoadingContract
    } = useGetContractByIdQuery(_id);

    const {data: dataAlternatives} = useGetSelectedAlternativesByContractQuery(_id ?? '');
    const alternatives: IAlternative[] = dataAlternatives ?? [];

    const filteredAlternative: boolean[] = useAppSelector((state) => state.alternative.filteredAlternative);

    const {data: dataParagraphs} = useGetParagraphsAndClausesQuery(_id ?? '');
    let filteredParagraphs: Paragraph[] = dataParagraphs ?? [];

    if (!filteredAlternative.every(item => !item)) {
        filteredParagraphs = filteredParagraphs.map(paragraph => {
            // ONLY SHOW CLAUSES WITH VOID OR UNFAIR REVIEW
            const filteredClauses = paragraph.clauses.filter(clause => {
                const latestReview = clause.latestReview;
                const assessment = latestReview?.assessment;
                const isAlternativeSelected = alternatives.some(alternative => alternative.clause === clause._id);
                return (assessment === Assessment.UNFAIR || assessment === Assessment.VOID) && (isAlternativeSelected ? filteredAlternative[1] : filteredAlternative[0]);
            });

            return {
                ...paragraph,
                clauses: filteredClauses
            };
        }).filter(paragraph => paragraph.clauses.length > 0);
    }

    const selectedClauseId: string = useAppSelector((state) => state.clause.selectedClauseId);

    /* SLICE STATE CHANGES */
    const dispatch = useAppDispatch();

    const deselectClause = (): void => {
        dispatch(setSelectedClauseId(''));
    };

    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%'
        }}>
            {isLoadingContract || contract === undefined ?
                <LoadingSpinner/> :
                <Box display={'flex'} flexDirection={'column'} alignItems={'center'} width={'100%'}>
                    <Grid container spacing={2} justifyContent="flex-start">
                        <Grid item xs={selectedClauseId ? 6 : 12} xl={selectedClauseId ? 8 : 12}>
                            <Card sx={{
                                width: '100%',
                                marginBottom: 1
                            }}>
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'space-between'
                                }}>
                                    <AlternativeFilter contractId={_id ?? ''}/>
                                </Box>
                            </Card>
                        </Grid> {selectedClauseId &&
                        <Grid item xs={selectedClauseId ? 6 : 0} xl={selectedClauseId ? 4 : 0}>
                            <Card sx={{
                                width: '100%',
                                marginBottom: 1
                            }}>
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'space-between'
                                }}>
                                    <AlternativeInfo clauseId={selectedClauseId}/>
                                </Box>
                            </Card>
                        </Grid>}
                    </Grid>
                    <Grid container spacing={2} justifyContent={'center'} sx={{
                        height: '75vh'
                    }}>
                        <Grid item xs={selectedClauseId ? 6 : 12} xl={selectedClauseId ? 8 : 12} sx={{
                            overflow: 'hidden',
                            height: '100%'
                        }}>
                            <ParagraphsAndClauses paragraphs={filteredParagraphs} isClassification={false} isAlternative={true}/>
                        </Grid> {selectedClauseId &&
                        <Grid item xs={selectedClauseId ? 6 : 0} xl={selectedClauseId ? 4 : 0} sx={{
                            height: '100%'
                        }}>
                            <AlternativeFormulation deselectClause={deselectClause} caseNumber={selectedClauseId}/>
                        </Grid>}
                    </Grid>
                </Box>}
        </ Box>);
};

export default AlternativePage;
