import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
    Box,
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Tooltip,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from "@mui/material";
import { Topic } from "../../features/Topic/types";

interface TopicTableProps {
    topics: Map<Topic, { ruleCount: number; templateCount: number; isDeletable: boolean }>;
    handleEdit: (topic: Topic) => Promise<void>;
    handleDeleteButton: (topicId: string) => Promise<void>;
}

export default function TopicTable(props: TopicTableProps) {
    const [confirmDialog, setConfirmDialog] = React.useState<{
        open: boolean;
        topic: Topic | null;
    }>({ open: false, topic: null });

    const handleDeleteClick = (topic: Topic) => {
        setConfirmDialog({ open: true, topic });
    };

    const handleConfirmDelete = async () => {
        if (confirmDialog.topic) {
            try {
                await props.handleDeleteButton(confirmDialog.topic.title.toString());
                setConfirmDialog({ open: false, topic: null });
            } catch (error) {
                console.error("Error deleting topic:", error);
            }
        }
    };

    const getTooltipText = (metadata: { ruleCount: number; templateCount: number; isDeletable: boolean }) => {
        if (!metadata.isDeletable) {
            const reasons = [];
            if (metadata.ruleCount > 0) reasons.push(`${metadata.ruleCount} Prüfanleitung${metadata.ruleCount !== 1 ? 'en' : ''}`);
            if (metadata.templateCount > 0) reasons.push(`${metadata.templateCount} Musterklausel${metadata.templateCount !== 1 ? 'n' : ''}`);
            
            return `Thema kann nicht gelöscht werden wegen: ${reasons.join(' und ')}`;
        }
        return "Löschen";
    };

    return (
        <>
            <TableContainer component={Paper} sx={{ maxHeight: '75vh' }}>
                <Table>
                    <TableHead>
                        <TableRow sx={{
                            backgroundColor: "#fcfcfc",
                        }}>
                            <TableCell width="60%">
                                <Box sx={{ textAlign: "left" }}>
                                    Thema
                                </Box>
                            </TableCell>
                            <TableCell width="15%">
                                <Box sx={{ textAlign: "left" }}>
                                    Prüfanleitungen
                                </Box>
                            </TableCell>
                            <TableCell width="15%">
                                <Box sx={{ textAlign: "left" }}>
                                    Musterklauseln
                                </Box>
                            </TableCell>
                            <TableCell width="10%">
                                <Box sx={{ textAlign: "right" }}></Box>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Array.from(props.topics.entries()).map(([topic, metadata], index) => (
                            <TableRow key={`${topic._id}-${index}`}>
                                <TableCell>
                                    <Box sx={{ textAlign: "left" }}>
                                        <Typography>{topic.title}</Typography>
                                    </Box>
                                </TableCell>
                                <TableCell>
                                    <Box sx={{ textAlign: "left" }}>
                                        <Typography>{metadata.ruleCount}</Typography>
                                    </Box>
                                </TableCell>
                                <TableCell>
                                    <Box sx={{ textAlign: "left" }}>
                                        <Typography>{metadata.templateCount}</Typography>
                                    </Box>
                                </TableCell>
                                <TableCell>
                                    <Box sx={{ textAlign: "right", display: "flex", justifyContent: "right" }}>
                                        <Tooltip title="Bearbeiten" placement="top">
                                            <Button
                                                variant="text"
                                                color="secondary"
                                                onClick={() => props.handleEdit(topic)}
                                                sx={{
                                                    minWidth: "auto",
                                                    "&:hover": {
                                                        color: "blue",
                                                    },
                                                }}
                                            >
                                                <EditIcon />
                                            </Button>
                                        </Tooltip>
                                        <Tooltip title={getTooltipText(metadata)}>
                                            <span>
                                                <Button
                                                    disabled={!metadata.isDeletable}
                                                    variant="text"
                                                    onClick={() => handleDeleteClick(topic)}
                                                    sx={{
                                                        color: "#d23939",
                                                        minWidth: "auto",
                                                        "&:hover": {
                                                            color: "#6e1d1d",
                                                        },
                                                    }}
                                                >
                                                    <DeleteIcon />
                                                </Button>
                                            </span>
                                        </Tooltip>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Dialog
                open={confirmDialog.open}
                onClose={() => setConfirmDialog({ open: false, topic: null })}
            >
                <DialogTitle>
                    Thema löschen
                </DialogTitle>
                <DialogContent>
                    <Typography>
                        Möchten Sie das Thema "{confirmDialog.topic?.title}" wirklich löschen?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setConfirmDialog({ open: false, topic: null })}
                        color="primary"
                    >
                        Abbrechen
                    </Button>
                    <Button
                        onClick={handleConfirmDelete}
                        variant="contained"
                        color="error"
                    >
                        Löschen
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}