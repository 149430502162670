import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
    Box,
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Tooltip
} from "@mui/material";
import { TemplateClause } from "../../features/TemplateClause/types";

interface TemplateClauseTableProps {
    templateClauses: TemplateClause[];
    handleDelete: (templateClause: TemplateClause) => Promise<void>;
    handleEdit: (templateClause: TemplateClause) => Promise<void>;
}

export default function TemplateClauseTable(props: TemplateClauseTableProps) {
    
    return (
        <TableContainer component={Paper} sx={{ maxHeight: "75vh" }}>
            <Table>
                <TableHead>
                    <TableRow sx={{
                        backgroundColor: "#fcfcfc",
                    }}>
                        <TableCell width="15%">
                            <Box
                                sx={{
                                    textAlign: "left",
                                }}
                            >
                                Thema
                            </Box>
                        </TableCell>
                        <TableCell width="70%">
                            <Box
                                sx={{
                                    textAlign: "left",
                                }}
                            >
                                Musterklausel
                            </Box>
                        </TableCell>
                        <TableCell width="15%">
                            <Box
                                sx={{
                                    textAlign: "right",
                                }}
                            ></Box>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.templateClauses.map((templateClause, index) => (
                        <TableRow key={index}>
                            <TableCell>
                                <Box
                                    sx={{
                                        textAlign: "left",
                                    }}
                                >
                                    <Typography>{templateClause.topic}</Typography>
                                </Box>
                            </TableCell>
                            <TableCell>
                                <Box
                                    sx={{
                                        textAlign: "left",
                                    }}
                                >
                                    <Typography style={{ whiteSpace: "pre-line" }}>
                                        {templateClause.text}
                                    </Typography>
                                </Box>
                            </TableCell>
                            <TableCell>
                                <Box sx={{ textAlign: "right", display: "flex", flexBasis: '1', justifyContent: "right" }}>
                                    <Tooltip title="Bearbeiten" placement="top">
                                        <Button
                                            variant="text"
                                            color="secondary"
                                            onClick={() => {
                                                props.handleEdit(templateClause).catch((error) => {
                                                    console.log(error);
                                                });
                                            }}
                                            sx={{
                                                minWidth: "auto",
                                                "&:hover": {
                                                    color: "blue",
                                                },
                                            }}
                                        >
                                            <EditIcon />
                                        </Button>
                                    </Tooltip>
                                    <Tooltip title="Löschen" placement="top">
                                        <Button
                                            variant="text"
                                            onClick={() => {
                                                props.handleDelete(templateClause).catch((error) => {
                                                    console.log(error);
                                                });
                                            }}
                                            sx={{
                                                color: "red",
                                                minWidth: "auto",
                                                "&:hover": {
                                                    color: "darkred",
                                                },
                                            }}
                                        >
                                            <DeleteIcon />
                                        </Button>
                                    </Tooltip>
                                </Box>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}