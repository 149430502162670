import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';

const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_BASE_URL as string,
    credentials: 'include', // include authorization via cookies
    mode: 'cors'
});

export const apiSlice = createApi({
    reducerPath: 'api',
    baseQuery,
    tagTypes: ['Contract', 'User', 'Paragraph', 'Clause', 'Review', 'Alternative', 'TemplateClause', 'Rule', 'Topic'],
    endpoints: (builder) => ({})
});
