import React from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Assessment } from '../../features/Clause/types';
import { blue } from '@mui/material/colors';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  '&.header': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontWeight: 'bold',
  },
  '&.rowHeader': {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
    fontWeight: 'bold',
  },
  '&.diagonal-header': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontWeight: 'bold',
    position: 'relative',
    padding: 0,
    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: `linear-gradient(to bottom left, transparent calc(50% - 1px), ${theme.palette.common.white}, transparent calc(50% + 1px))`,
    },
    '& .top-text': {
      position: 'absolute',
      right: '15px',
      top: '10px',
    },
    '& .bottom-text': {
      position: 'absolute',
      left: '15px',
      bottom: '10px',
    },
  },
}));

interface TimeRange {
  start: Date;
  end: Date;
  label?: string;
}

export interface MatrixTableProps {
  matrixData: Record<Assessment, Record<Assessment, number>>;
  total: number;
  correct: number;
  timeRange: TimeRange | null;
}

const MatrixTable: React.FC<MatrixTableProps> = ({ matrixData, total, correct, timeRange }) => {
  const displayedAssessments = Object.values(Assessment).filter(
    assessment => assessment !== Assessment.TOREASSESS
  );

  const getCellColor = (value: number) => {
    if (value === null) return '#f5f5f5';
    const intensity = Math.floor((value / correct) * 16);
    switch (intensity) {
      case 0: return '#fff';
      case 1: return blue[200];
      case 2: return blue[300];
      case 3: return blue[300];
      case 4: return blue[400];
      case 5: return blue[400];
      case 6: return blue[400];
      case 7: return blue[400];
      case 8: return blue[600];
      case 9: return blue[600];
      case 10: return blue[600];
      case 11: return blue[600];
      case 12: return blue[600];
      case 13: return blue[600];
      case 14: return blue[600];
      case 15: return blue[600];
      case 16: return blue[700];
      default: return blue[800];
    }
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        {timeRange?.start.toDateString() !== new Date(0).toDateString() && (
          <Typography variant="body1" color="primary">
            {timeRange?.start.toLocaleDateString()} - {timeRange?.end.toLocaleDateString()}
          </Typography>
        )}
        {timeRange?.start.toDateString() === new Date(0).toDateString() && (
          <Typography variant="body1" color="primary">
            Beginn - {timeRange?.end.toLocaleDateString()}
          </Typography>
        )}
      </Typography>
      <Typography variant="subtitle2" color="textSecondary" gutterBottom>
        Genauigkeit: {((correct / total) * 100).toFixed(1)}% ({correct}/{total})
      </Typography>
      <TableContainer component={Paper} elevation={0}>
        <Table size="medium">
          <TableHead>
            <TableRow>
              <StyledTableCell className="diagonal-header">
                <span className="top-text">Anwalt</span>
                <span className="bottom-text">LLM</span>
              </StyledTableCell>
              {displayedAssessments.map(assessment => (
                <StyledTableCell key={assessment} align="center" className="header">
                  {assessment}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {displayedAssessments.map(llmAssessment => (
              <TableRow key={llmAssessment}>
                <StyledTableCell className="rowHeader">
                  {llmAssessment}
                </StyledTableCell>
                {displayedAssessments.map(userAssessment => (
                  <TableCell
                    key={`${llmAssessment}-${userAssessment}`}
                    align="center"
                    sx={{
                      backgroundColor: getCellColor(matrixData[llmAssessment][userAssessment]),
                      color: 'common.black',
                      fontWeight: llmAssessment === userAssessment ? 'bold' : 'normal',
                      border: llmAssessment === userAssessment ? '2px solid' : '1px solid',
                      borderColor: llmAssessment === userAssessment ? 'primary.main' : 'primary.light',
                    }}
                  >
                    {(matrixData[llmAssessment][userAssessment] / total * 100).toFixed(2)} %
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default MatrixTable;