import {Box, Card, Typography} from '@mui/material';
import React from 'react';
import {useParams} from 'react-router-dom';
import {useGetContractByIdQuery} from '../../features/Contract/ContractApiSlice';
import LoadingSpinner from '../general/LoadingSpinner';
import {useGetUserByIdQuery} from '../../features/User/UserApiSlice';

const ContractInfo = (): any => {
    const {_id} = useParams();
    const {data: contract} = useGetContractByIdQuery(_id);

    const {data: contractUser} = useGetUserByIdQuery(contract?.owner ?? '');

    return (
        <Card sx={{
            height: '100%'
        }}>
            {contract === undefined ?
                <LoadingSpinner/> :
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'space-around',
                    height: '100%'
                }}>
                    <Typography variant="h4" fontWeight={'bold'} color={'primary.main'}>
                        {contract.title}
                    </Typography>
                    <Box display={'flex'} flexDirection={'row'}>
                        <Typography color="text.secondary" marginRight={2}>
                            {`Erstellt von: ${contractUser?.firstName + ' ' + contractUser?.lastName}`}
                        </Typography>
                        <Typography color="text.secondary">
                            {`Erstellt am: ${new Date(contract.createdAt).toLocaleDateString('de-DE', {
                                day: '2-digit',
                                month: '2-digit',
                                year: '2-digit'
                            })}`}
                        </Typography>
                    </Box>
                </Box>}
        </Card>);
};

export default ContractInfo;
