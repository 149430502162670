import {Chip} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {useGetAlternativesByClauseQuery} from '../../../features/Alternative/AlternativeApiSlice';

interface AlternativeSelectedBadgeProps {
    clauseId: string
}

const AlternativeSelectedBadge = ({clauseId}: AlternativeSelectedBadgeProps): any => {
    const {data: alternatives} = useGetAlternativesByClauseQuery({clauseId});

    const [alternativeSelected, setAlternativeSelected] = useState<boolean>(false);

    useEffect(() => {
        if (alternatives) {
            setAlternativeSelected(alternatives.some((alternative) => alternative.selected));
        }
    }, [alternatives]);

    return (
        <Chip label={alternativeSelected ? 'Alternative Formulierung' : 'Originale Formulierung'} sx={{
            backgroundColor: alternativeSelected ? '#faf2e5' : '#dcf2ff',
            color: alternativeSelected ? '#e1c055' : '#5b92cd',
            border: `1px solid ${alternativeSelected ? '#e1c055' : '#5b92cd'}`,
            borderRadius: '8px'
        }}/>);
};

export default AlternativeSelectedBadge;
