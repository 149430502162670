import {Chip, colors} from '@mui/material';
import React from 'react';
import {type ContractState} from '../../features/Contract/types';

interface WorkflowStateBadgeProps {
    contractState: ContractState
}

interface StateProperties {
    text: string
    backgroundColor: string
    color: string
}

const WorkflowStateBadge: React.FC<WorkflowStateBadgeProps> = ({contractState}) => {
    const stateProperties: Record<ContractState, StateProperties> = {
        CREATED: {
            text: 'Erstellt',
            backgroundColor: '#f0f0f0',
            color: '#333'
        },
        PDF_UPLOADED: {
            text: 'PDF hochgeladen',
            backgroundColor: '#e6e6e6',
            color: '#444'
        },
        OCR_RUNNING: {
            text: 'OCR läuft',
            backgroundColor: '#fef3e6',
            color: '#ff8c00'
        },
        EXTERNAL_CLASSIFICATION_RUNNING: {
            text: 'LLM Berwertung läuft',
            backgroundColor: '#fff9e6',
            color: '#e6ac00'
        },
        EXTERNAL_CLASSIFICATION_DONE: {
            text: 'Von LLM bewertet',
            backgroundColor: '#ffedb3',
            color: '#e6ac00'
        },
        INTERNAL_CLASSIFICATION: {
            text: 'Anwalt Bewertung läuft',
            backgroundColor: '#f5efe6',
            color: '#cc9933'
        },
        FINISHED_REVIEW: {
            text: 'Von Anwalt bewertet',
            backgroundColor: '#e8fff2',
            color: '#47c78d'
        },
        ALTERNATIVE_FORMULATIONS: {
            text: 'Alternative Formulierungen',
            backgroundColor: '#e6f0ff',
            color: '#3366cc'
        },
        FINISHED: {
            text: 'Komplett abgeschlossen',
            backgroundColor: '#007f5f',
            color: '#fff'
        }
    };

    return (
        <Chip label={stateProperties[contractState]?.text ?? contractState} sx={{
            backgroundColor: stateProperties[contractState]?.backgroundColor ?? '#faf2e5',
            color: stateProperties[contractState]?.color ?? '#e1c055',
            border: `1px solid ${stateProperties[contractState]?.color ?? '#e1c055'}`,
            borderRadius: '8px',
            width: '100%'
        }}/>);
};

export default WorkflowStateBadge;
