import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import type {RootState} from '../../app/store';
import type {Contract, ContractSliceInterface} from './types';

export const initialState: ContractSliceInterface = {
    filteredAssessment: [false, false, false, false],
    selectedContract: null
};

export const ContractSlice = createSlice({
    name: 'contract',
    initialState,
    reducers: {
        setSelectedContract: (state, action: PayloadAction<Contract | null>) => {
            state.selectedContract = action.payload;
            return state;
        },
        setFilteredAssessment: (state, action: PayloadAction<boolean[]>) => {
            state.filteredAssessment = action.payload;
            return state;
        }
    }
});

export const {
    setSelectedContract,
    setFilteredAssessment
} = ContractSlice.actions;

export const contract = (state: RootState): ContractSliceInterface => state.contract;

export default ContractSlice.reducer;
