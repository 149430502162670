import {Box, Button, Card, Step, StepLabel, Stepper, Typography} from '@mui/material';
import React from 'react';
import DownloadContractButton from './DownloadContractButton';
import {setSelectedClauseId} from '../../features/Clause/ClauseSlice';
import {setSelectedParagraphId} from '../../features/Paragraph/ParagraphSlice';
import {useAppDispatch} from '../../hooks/typedReduxHooks';
import {useGetContractByIdQuery} from '../../features/Contract/ContractApiSlice';
import {useParams} from 'react-router-dom';
import {ContractState} from '../../features/Contract/types';

const steps = ['Fairness-Bewertung', 'Alternative Formulierungen'];

interface WorkflowStepperProps {
    activeStep: any
    handleNext: () => void
    handleBack: () => void
}

const WorkflowStepper: React.FC<WorkflowStepperProps> = ({
    activeStep,
    handleNext,
    handleBack
}: WorkflowStepperProps) => {
    const dispatch = useAppDispatch();
    const {_id} = useParams();

    const { data: contract } = useGetContractByIdQuery(_id);

    return (
        <Card>
            <Box sx={{
                margin: 2
            }}>
                <Stepper activeStep={activeStep}>
                    {steps.map((label, index) => (
                        <Step key={index}>
                            <StepLabel>{label}</StepLabel>
                        </Step>))}
                </Stepper>

                <Box>
                    {activeStep === steps.length ? (
                        <Box>
                            <Typography>All steps completed</Typography>
                        </Box>) : (
                        <Box mt={2}>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}>
                                <Button color="inherit" disabled={activeStep === 0} onClick={() => {
                                    dispatch(setSelectedClauseId(''));
                                    dispatch(setSelectedParagraphId(''));
                                    handleBack();
                                }}>
                                    <Typography variant="body1"> Back </Typography>
                                </Button> {activeStep < steps.length - 1 ? (
                                <Button disabled={!contract || !(contract.state === ContractState.FINISHED_REVIEW || contract.state === ContractState.ALTERNATIVE_FORMULATIONS || contract.state === ContractState.FINISHED)} onClick={() => {
                                    dispatch(setSelectedClauseId(''));
                                    dispatch(setSelectedParagraphId(''));
                                    handleNext();
                                }} variant="contained">
                                    <Typography variant="body1">Next</Typography>
                                </Button>) : (
                                <DownloadContractButton/>)}
                            </Box>
                        </Box>)}
                </Box>
            </Box>
        </Card>);
};

export default WorkflowStepper;
