import {apiSlice} from '../../app/api/apiSlice';
import {type IAlternative} from './types';
import {type Paragraph} from '../Paragraph/types';

export const AlternativeApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getAlternativesByClause: builder.query<IAlternative[], { clauseId: string, N_ALTERNATIVES?: number }>({
            query: ({
                clauseId,
                N_ALTERNATIVES
            }) => `/alternatives/byClause/${clauseId}?n_alternatives=${N_ALTERNATIVES ?? 6}`,
            providesTags: (result, error, {clauseId}) => (result ? [{
                type: 'Alternative',
                id: clauseId
            }] : [])
        }),
        getSelectedAlternativesByContract: builder.query<IAlternative[], string>({
            query: (contractId) => `/alternatives/byContract/${contractId}`,
            providesTags: (result, error, arg) => result != null ? [...result.map(({clause}) => ({
                type: 'Alternative' as const,
                clause
            })), {
                type: 'Alternative',
                id: 'LIST'
            }] : [{
                type: 'Alternative',
                id: 'LIST'
            }]
        }),
        createAlternative: builder.mutation<IAlternative, { text: string, clause: string }>({
            query: (requestBody) => ({
                url: `/alternatives`,
                method: 'POST',
                body: requestBody
            }),
            invalidatesTags: ['Alternative']
        }),
        updateAlternative: builder.mutation<IAlternative | { error: string }, { alternativeId: string, requestBody: { text?: string, selected?: boolean } }>({
            query: ({
                alternativeId,
                requestBody
            }) => ({
                url: `/alternatives/${alternativeId}`,
                method: 'PUT',
                body: requestBody
            }),
            invalidatesTags: ['Alternative']
        }),
        getContractWithAlternatives: builder.query<Paragraph[], string>({
            query: (contractId) => `/alternatives/contractWithAlternatives/${contractId}`,
            providesTags: (result, error, arg) => result != null ? [{
                type: 'Alternative',
                id: 'LIST'
            }] : []
        })
    })
});

export const {
    useUpdateAlternativeMutation,
    useGetAlternativesByClauseQuery,
    useCreateAlternativeMutation,
    useGetSelectedAlternativesByContractQuery,
    useGetContractWithAlternativesQuery
} = AlternativeApiSlice;

export const {
    endpoints: {
        updateAlternative,
        getAlternativesByClause,
        createAlternative,
        getSelectedAlternativesByContract,
        getContractWithAlternatives
    }
} = AlternativeApiSlice;
