import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import type {AlternativeSliceInterface} from './types';

export const initialState: AlternativeSliceInterface = {
    filteredAlternative: [true, true]
};

export const AlternativeSlice = createSlice({
    name: 'alternative',
    initialState,
    reducers: {
        setFilteredAlternative: (state, action: PayloadAction<boolean[]>) => {
            state.filteredAlternative = action.payload;
            return state;
        }
    }
});

export const {
    setFilteredAlternative
} = AlternativeSlice.actions;

export default AlternativeSlice.reducer;
