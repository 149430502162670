import React, {useState} from 'react';
import {Box, Button, Card, CardContent, IconButton, TextField, Tooltip, Typography} from '@mui/material';
import {useUpdateAlternativeMutation} from '../../../features/Alternative/AlternativeApiSlice';
import {type IAlternative} from '../../../features/Alternative/types';
import EditIcon from '@mui/icons-material/Edit';
import FormDialog from '../../general/FormDialog';
import {ContractState} from '../../../features/Contract/types';
import {useUpdateContractMutation} from '../../../features/Contract/ContractApiSlice';
import {useParams} from 'react-router-dom';

interface AlternativeProps {
    alternative: IAlternative
}

const Alternative = ({alternative}: AlternativeProps): any => {
    const {_id: contractId} = useParams();

    const [updateAlternative] = useUpdateAlternativeMutation();
    const [updateContract] = useUpdateContractMutation();

    const [open, setOpen] = useState(false);

    const handleClickOpen = (): void => {
        setOpen(true);
    };

    const handleClose = (): void => {
        setOpen(false);
    };

    const [text, updateText] = useState(alternative.text);

    return (<>
        <FormDialog open={open} handleClose={handleClose}>
            <Box sx={{width: '400px'}}>
                <Typography fontWeight="bold">Formulierung anpassen</Typography>
                <Box my={2}>
                    <TextField variant="outlined" fullWidth value={text} multiline rows={6} onChange={(e) => {
                        updateText(e.target.value);
                    }}/>
                </Box>
                <Button variant="contained" startIcon={
                    <EditIcon/>} fullWidth onClick={(e) => {
                    updateAlternative({
                        alternativeId: alternative._id,
                        requestBody: {text}
                    }).then(() => {
                        handleClose();
                    }).catch((error) => {
                        console.log(error);
                    });
                }}> Formulierung ändern </Button>
            </Box>
        </FormDialog>
        <Card sx={{
            '&:hover': {
                cursor: 'pointer'
            },
            mb: 2,
            boxShadow: 'none',
            border: '2px solid',
            borderColor: alternative.selected ? 'lightgrey' : 'transparent',
            backgroundColor: alternative.selected ? '#F0F8FF' : 'white'
        }} onClick={(e) => {
            updateAlternative({
                alternativeId: alternative._id,
                requestBody: {selected: true}
            }).then(async () => {
                await updateContract({
                    contractId: contractId ?? '',
                    requestBody: {state: ContractState.ALTERNATIVE_FORMULATIONS}
                });
            }).catch((error) => {
                console.log(error);
            });
        }}>
            <CardContent sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-evenly'
            }}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    p: 1
                }}>
                    <Typography mr={2}>{alternative.text}</Typography>
                    <Tooltip title="Text bearbeiten" arrow>
                        <IconButton sx={{
                            width: 48,
                            height: 48
                        }} onClick={handleClickOpen}>
                            <EditIcon/>
                        </IconButton>
                    </Tooltip>
                </Box>
            </CardContent>
        </Card>
    </>);
};

export default Alternative;
