import React, { useEffect, useRef, useMemo, useState } from 'react';
import ApexCharts from 'apexcharts';
import { ApexOptions } from 'apexcharts';
import { Stack, Autocomplete, TextField, Chip, Typography, Box, Tooltip, IconButton, FormControl, Select, MenuItem } from '@mui/material';
import { Assessment } from '../../features/Clause/types';
import { uniqBy } from 'lodash';
import { HelpOutlineOutlined } from '@mui/icons-material';

interface AssessmentPair {
    llmAssessment: Assessment;
    userAssessment: Assessment;
    date: string;
    rules: Array<{
        ruleId: string;
        ruleText: string;
        topic: string;
        version: number;
    }>;
}

interface AccuracyScoreProps {
    assessmentPairs: AssessmentPair[];
}

const timeRangeOptions = [
    {
        label: 'Letzte 7 Tage',
        getValue: () => ({
            start: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000),
            end: new Date()
        })
    },
    {
        label: 'Letzte 30 Tage',
        getValue: () => ({
            start: new Date(Date.now() - 30 * 24 * 60 * 60 * 1000),
            end: new Date()
        })
    },
    {
        label: 'Letzte 90 Tage',
        getValue: () => ({
            start: new Date(Date.now() - 90 * 24 * 60 * 60 * 1000),
            end: new Date()
        })
    },
    {
        label: 'Dieses Jahr',
        getValue: () => ({
            start: new Date(new Date().getFullYear(), 0, 1),
            end: new Date()
        })
    },
    {
        label: 'Gesamt',
        getValue: () => ({
            start: new Date(0),
            end: new Date()
        })
    }
];

const calculateYAxisBounds = (values: number[]) => {
    if (values.length === 0) return { min: 0, max: 100 };

    const min = Math.min(...values);
    const max = Math.max(...values);
    const range = max - min;

    if (min > 40) {
        const padding = range * 0.1;
        const ceil = Math.ceil((max + padding) / 5) * 5
        const floor = Math.floor((min - padding) / 5) * 5
        return {
            min: floor < 0 ? 0 : floor,
            max: ceil > 100 ? 100 : ceil
        };
    }

    return { min: 0, max: 100 };
};

const AccuracyScore: React.FC<AccuracyScoreProps> = ({ assessmentPairs }) => {
    const chartRef = useRef<HTMLDivElement>(null);
    const chartInstance = useRef<ApexCharts | null>(null);
    const [timeRangeIndex, setTimeRangeIndex] = useState(2);
    const [selectedRules, setSelectedRules] = useState<string[]>([]);

    const availableRules = useMemo(() =>
        uniqBy(
            assessmentPairs.flatMap(pair => pair.rules),
            'ruleId'
        ).filter(rule => rule),
        [assessmentPairs]
    );

    const filteredData = useMemo(() => {
        const timeRange = timeRangeOptions[timeRangeIndex].getValue();

        return assessmentPairs
            .filter(pair => {
                const date = new Date(pair.date);
                const isInTimeRange = date >= timeRange.start && date <= timeRange.end;

                if (selectedRules.length === 0) return isInTimeRange;
                return isInTimeRange && pair.rules.some(rule => selectedRules.includes(rule.ruleId));
            });
    }, [assessmentPairs, timeRangeIndex, selectedRules]);

    // Calculate daily accuracy data
    const chartData = useMemo(() => {
        // Group assessments by day
        const dailyData = new Map<string, { correct: number; total: number }>();
        let cumulativeCorrect = 0;
        let cumulativeTotal = 0;

        // Sort the data by date first
        const sortedData = [...filteredData].sort(
            (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
        );

        sortedData.forEach(pair => {
            const dayKey = new Date(pair.date).toISOString().split('T')[0];
            const isCorrect = pair.llmAssessment === pair.userAssessment;
            
            cumulativeCorrect += isCorrect ? 1 : 0;
            cumulativeTotal += 1;

            dailyData.set(dayKey, {
                correct: cumulativeCorrect,
                total: cumulativeTotal
            });
        });

        // Convert the Map to array of [timestamp, accuracy] pairs
        return Array.from(dailyData.entries()).map(([day, { correct, total }]) => [
            new Date(day).getTime(),
            (correct / total) * 100
        ]);
    }, [filteredData]);

    const yAxisBounds = useMemo(() => {
        const accuracyValues = chartData.map(point => point[1]);
        return calculateYAxisBounds(accuracyValues);
    }, [chartData]);

    const options: ApexOptions = {
        chart: {
            defaultLocale: 'de',
            locales: [{
                name: 'de',
                options: {
                    months: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
                    shortMonths: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
                    days: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
                    shortDays: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa']
                }
            }],
            type: 'line',
            height: 350,
            zoom: {
                enabled: false
            },
            toolbar: {
                show: true
            },
            animations: {
                enabled: true,
                easing: 'easeinout',
                speed: 800,
            }
        },
        series: [{
            name: 'Genauigkeit',
            data: chartData
        }],
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'smooth',
            width: 3
        },
        grid: {
            row: {
                colors: ['#f3f3f3', 'transparent'],
                opacity: 0.5
            }
        },
        xaxis: {
            type: 'datetime',
            labels: {
                datetimeUTC: false,
                format: 'dd MMM yyyy'
            },
            title: {
                text: 'Datum'
            }
        },
        yaxis: {
            title: {
                text: 'Genauigkeit in %'
            },
            min: yAxisBounds.min,
            max: yAxisBounds.max,
            tickAmount: 5,
            labels: {
                formatter: (value) => value.toFixed(0)
            }
        },
        tooltip: {
            x: {
                format: 'dd MMM yyyy'
            },
            y: {
                formatter: (value) => `${value.toFixed(1)}%`
            }
        },
        markers: {
            size: 4,
            hover: {
                size: 6
            }
        }
    };

    useEffect(() => {
        if (chartRef.current) {
            if (chartInstance.current) {
                chartInstance.current.destroy();
            }

            chartInstance.current = new ApexCharts(chartRef.current, options);
            chartInstance.current.render();
        }

        return () => {
            if (chartInstance.current) {
                chartInstance.current.destroy();
            }
        };
    }, [options]);

    return (
        <Box sx={{ padding: 3 }} alignItems="center">
            <Typography variant="h5" gutterBottom>
                Genauigkeit der LLMs im Zeitverlauf
                <Tooltip 
                    sx={{ padding: 0, marginLeft: 1 }} 
                    title={
                        <Typography sx={{ margin: 0, padding: 0 }}>
                            <b>Jede Bewertung des LLMs im Vergleich zu der Korrektur der Anwälte.</b><br />
                            <b>Je höher der Wert, desto besser ist das LLM</b> 
                            <br /><br />
                            Ein höherer prozentualer Wert gibt eine geringe Fehlerquote des LLMs an.<br /> 
                            Ein niedrigerer Wert indiziert, dass der Anwalt mehrere Korrekturen vornehmen musste.<br />
                            Genauere Informationen zu den Klassifikationen sind der Confusion Matrix zu entnehmen.
                        </Typography>
                    } 
                    componentsProps={{
                        tooltip: {
                            sx: {
                                bgcolor: 'common.white',
                                color: 'text.primary',
                                boxShadow: 2,
                            },
                        },
                    }}
                >
                    <IconButton>
                        <HelpOutlineOutlined />
                    </IconButton>
                </Tooltip>
            </Typography>
                    
            <Stack spacing={2} marginTop={3}>
                <Stack direction="row" spacing={2}>
                    <FormControl sx={{ minWidth: 200 }}>
                        <Select
                            value={timeRangeIndex}
                            onChange={(e) => setTimeRangeIndex(e.target.value as number)}
                        >
                            {timeRangeOptions.map((option, index) => (
                                <MenuItem key={option.label} value={index}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <Autocomplete
                        multiple
                        sx={{ flexGrow: 1 }}
                        options={availableRules}
                        getOptionLabel={(option) => option.ruleText}
                        value={availableRules.filter(rule => selectedRules.includes(rule.ruleId))}
                        onChange={(_, newValue) => setSelectedRules(newValue.map(rule => rule.ruleId))}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder="Nach Prüfanleitungen filtern"
                            />
                        )}
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                                <Chip
                                    label={option.topic}
                                    {...getTagProps({ index })}
                                />
                            ))
                        }
                    />
                </Stack>
            </Stack>

            <div ref={chartRef} />
        </Box>
    );
};

export default AccuracyScore;