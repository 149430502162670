import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Typography} from '@mui/material';
import React, {useState} from 'react';
import {useDeleteParagraphMutation, useDowngradeParagraphMutation} from '../../../features/Paragraph/ParagraphApiSlice';
import {setEditParagraphId, setNewParagraphText} from '../../../features/Paragraph/ParagraphSlice';
import {type Paragraph} from '../../../features/Paragraph/types';
import {useAppDispatch} from '../../../hooks/typedReduxHooks';
import ConfirmActionDialog from '../../general/ConfirmActionDialog';

const OptionsMenuParagraph: React.FC<{ paragraph: Paragraph }> = ({paragraph}): any => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    /* SLICE STATE CHANGES */
    const dispatch = useAppDispatch();

    /* PARAGRAPH MUTATIONS */
    const [deleteParagraph] = useDeleteParagraphMutation();
    const [downgradeParagraph] = useDowngradeParagraphMutation();

    /* STATES FOR DIALOG WINDOW */
    const [openDialog, setOpenDialog] = useState(false);
    const [actionToConfirm, setActionToConfirm] = useState<() => void>(() => {
    });

    /* OPEN AND CLOSE */
    const handleClick = (event: any): void => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (event: any): void => {
        event.stopPropagation();
        setAnchorEl(null);
    };

    /* CHANGE OPERATIONS ON CLAUSE */
    const handleDeleteParagraph = async (paragraphToDelete: Paragraph): Promise<void> => {
        await deleteParagraph(paragraphToDelete._id).then(response => {
            console.log(response);
        }).catch(error => {
            console.log(error);
        });
    };

    const handleDowngradeParagraph = async (paragraphToDowngrade: Paragraph): Promise<void> => {
        await downgradeParagraph(paragraphToDowngrade._id).then(response => {
            console.log(response);
        }).catch(error => {
            console.log(error);
        });
    };

    /* DIALOG ACTIONS TO CONFIRM CHANGE OPERATION */
    const handleOpenDialog = (action: () => void): void => {
        setOpenDialog(true);
        setActionToConfirm(() => action);
    };

    const handleCloseDialog = (): void => {
        setOpenDialog(false);
        setActionToConfirm(() => {
        });
    };

    const actionText = ['Paragraph Text bearbeiten', 'Zu Klausel umwandeln', 'Paragraph löschen'];

    const actionMethods = [() => {
        dispatch(setEditParagraphId(paragraph._id));
        dispatch(setNewParagraphText(paragraph.content));
    }, () => {
        handleOpenDialog(() => {
            void handleDowngradeParagraph(paragraph);
        });
    }, () => {
        handleOpenDialog(() => {
            void handleDeleteParagraph(paragraph);
        });
    }];

    const actionIcons = [
        <EditIcon key={'Edit'}/>,
        <FileDownloadIcon key={'Clause'}/>,
        <DeleteIcon fontSize="small" key={'Delete'}/>];

    return (<>
        <ConfirmActionDialog openDialog={openDialog} handleCloseDialog={handleCloseDialog} actionToConfirm={actionToConfirm}/>
        <IconButton id="basic-button" aria-controls={open ? 'basic-menu' : undefined} aria-haspopup="true" aria-expanded={open ? 'true' : undefined} onClick={handleClick}>
            <MoreVertIcon/>
        </IconButton>
        <Menu anchorEl={anchorEl} open={open} onClose={handleClose} keepMounted>

            {actionText.map((text, index) =>
                <MenuItem onClick={actionMethods[index]} key={index} sx={{
                    borderRadius: '8px',
                    mx: 1,
                    pr: 0
                }}>
                    <ListItemText>
                        <Typography>
                            {text}
                        </Typography>
                    </ListItemText>
                    <ListItemIcon sx={{
                        ml: 1,
                        mr: 0
                    }}>
                        {actionIcons[index]}
                    </ListItemIcon>
                </MenuItem>)}
        </Menu>
    </>);
};

export default OptionsMenuParagraph;
