import {Box, Typography} from '@mui/material';
import React from 'react';
import {Assessment} from '../../../features/Clause/types';
import {type Review} from '../../../features/Review/types';
import {useGetUserByIdQuery} from '../../../features/User/UserApiSlice';
import { useGetRulesByReviewIdQuery } from '../../../features/Review/ReviewApiSlice';

const ClassificationLawyer: React.FC<{ review: Review }> = ({review}): any => {
    const { data: user } = useGetUserByIdQuery(review.author, {
        skip: review.isLLM
    });
    const { data: rules } = useGetRulesByReviewIdQuery(review._id);

    return (
        <Box sx={{
            marginTop: 1.5,
            marginBottom: 1.5,
            display: 'flex',
            flexDirection: 'row'
        }}>
            <Box sx={{
                minWidth: '20px',
                height: '20px',
                marginRight: 2,
                borderRadius: '50%',
                backgroundColor: review.assessment === Assessment.VALID ? 'assessment.valid' : review.assessment === Assessment.UNFAIR ? 'assessment.unfair' : review.assessment === Assessment.TOREASSESS ? 'assessment.toreassess' : 'assessment.void'
            }}/>
            <Box>
                <Typography sx={{
                    marginBottom: 0.5,
                    fontWeight: 'bold'
                }}>
                    {review.isLLM ? "AI Assessment" : user?.firstName + ' ' + user?.lastName}
                </Typography>
                <Typography>
                    {review.comment} 
                    {rules && rules.length > 0 && (
                        <>
                            <br />
                            <br />
                            <span style={{ fontWeight: 'bold' }}>
                                Angewandte Regeln:
                            </span>
                        </>
                    )}
                    {rules && rules.map((rule, index) => {
                        return (
                            <li key={index}>
                                {index !== 0 ? '\n' : ' '}<span>{rule.text}</span>
                            </li>
                        );
                    })}
                </Typography>
            </Box>
        </Box>);
};

export default ClassificationLawyer;
