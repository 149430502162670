import React, { useEffect, useMemo, useState } from 'react';
import { Box, Typography, Paper, Tabs, Tab, Skeleton } from '@mui/material';
import {
  useGetAnalysisObjectsQuery,
  useGetBasicMetaQuery,
  useGetInvalidReviewsQuery,
  useGetContractByClauseIdMutation,
  useGetConfusionMatrixDataQuery,
  useGetMetricsDataQuery
} from '../../features/Analysis/AnalysisApiSlice';
import BasicMetas from './BasicMetas';
import ConfidenceScore from './ConfidenceScore';
import RuleCorpusSize from './RuleCorpusSize';
import ContractAnalysis from './ContractAnalysis';
import ConfusionMatrix from './ConfusionMatrix';
import AccuracyScore from './AccuracyScore';
import TimePerContract from './TimePerContract';
import RuleCoverage from './RuleCoverage';
import { Review } from '../../features/Review/types';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const NoDataAvailable = ({ ifNoDataText }: { ifNoDataText?: string }) => (
  <Box sx={{
    p: 3,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'grey.50',
    borderRadius: 1
  }}>
    <Typography color="text.secondary">
      {ifNoDataText || 'Hierfür gibt es bisher nicht genug Daten.'}
    </Typography>
  </Box>
);

const MetricLoadingPlaceholder = () => (
  <Box sx={{ p: 2 }}>
    <Skeleton variant="text" width="200px" height={32} sx={{ mb: 2 }} />
    <Skeleton variant="rectangular" height={350} />
  </Box>
);

const TabPanel: React.FC<TabPanelProps> = ({ children, value, index, ...other }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`analysis-tabpanel-${index}`}
    aria-labelledby={`analysis-tab-${index}`}
    {...other}
  >
    {value === index && (
      <Box sx={{ pt: 3 }}>
        {children}
      </Box>
    )}
  </div>
);

interface MetricContainerProps<T> {
  isLoading: boolean;
  isError: boolean;
  data: T | undefined;
  component: React.ComponentType<any>;
  transformData?: (data: T) => any;
  ifNoDataText?: string;
}

const MetricContainer = <T,>({ isLoading, isError, data, component: Component, transformData = (data: T) => data, ifNoDataText }: MetricContainerProps<T>) => {
  if (isLoading) return <MetricLoadingPlaceholder />;
  if (isError) return <NoDataAvailable />;
  if (!data) return <NoDataAvailable ifNoDataText={ifNoDataText} />;

  const transformedProps = transformData(data);
  return <Component {...transformedProps} />;
};

const AnalysisPage = () => {
  const [tabValue, setTabValue] = useState(0);
  const [contractMapData, setContractMapData] = useState<any>([]);

  const dateRange = useMemo(() => ({
    from: new Date(Date.now() - 365 * 24 * 60 * 60 * 1000),
    to: new Date()
  }), []);

  const basicMetaQuery = useGetBasicMetaQuery();
  const metricsDataQuery = useGetMetricsDataQuery(null);
  const analysisDataQuery = useGetAnalysisObjectsQuery();
  const invalidReviewsQuery = useGetInvalidReviewsQuery();
  const confusionMatrixQuery = useGetConfusionMatrixDataQuery(dateRange);

  const [getContractByClauseId, { isLoading: isContractLoading, isError: isContractError }] = useGetContractByClauseIdMutation();

  useEffect(() => {
    const fetchContracts = async () => {
      if (!invalidReviewsQuery.isLoading && !invalidReviewsQuery.isError && invalidReviewsQuery.data) {
        try {
          const clauseIds = invalidReviewsQuery.data.map((review: Review) => review.clause);
          const result = await getContractByClauseId(clauseIds).unwrap();
          setContractMapData(result);
        } catch (error) {
          console.error('Error fetching contracts:', error);
        }
      }
    };

    fetchContracts();
  }, [invalidReviewsQuery.data, invalidReviewsQuery.isLoading, invalidReviewsQuery.isError, getContractByClauseId]);

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      maxHeight: '90vh',
      mt: 5,
    }}>
      <Box sx={{
        mt: 5,
        width: '100%',
        maxHeight: '90vh',
        overflowY: 'auto',
      }}>
        <Typography variant="h4" gutterBottom>Vertragsanalyse</Typography>

        <Box sx={{ width: '100%' }}>
          <Tabs
            value={tabValue}
            onChange={(_, newValue) => setTabValue(newValue)}
            variant="fullWidth"
          >
            <Tab label="Systeminformationen" />
            <Tab label="Detailinformationen" />
            <Tab label="Vertragsanalyse" />
          </Tabs>
        </Box>

        <TabPanel value={tabValue} index={0}>
          <Box sx={{ width: '60%', mx: 'auto' }}>
            <Box sx={{ py: 2, mt: 2 }}>
              <MetricContainer
                isLoading={basicMetaQuery.isLoading}
                isError={basicMetaQuery.isError}
                data={basicMetaQuery.data}
                component={BasicMetas}
                transformData={(data) => ({ basicMeta: data })}
              />
            </Box>

            <Paper sx={{ p: 2, mt: 2 }} elevation={0}>
              <MetricContainer
                isLoading={metricsDataQuery.isLoading}
                isError={metricsDataQuery.isError}
                data={metricsDataQuery.data}
                component={TimePerContract}
                transformData={(data) => ({ metricsData: data })}
              />
            </Paper>

            <Paper sx={{ p: 2, mt: 2 }} elevation={0}>
              <MetricContainer
                isLoading={basicMetaQuery.isLoading}
                isError={basicMetaQuery.isError}
                data={basicMetaQuery.data}
                component={RuleCorpusSize}
                transformData={(data) => ({ analysisData: data.rules })}
              />
            </Paper>

            <Paper sx={{ p: 2, mt: 2 }} elevation={0}>
              <MetricContainer
                isLoading={basicMetaQuery.isLoading}
                isError={basicMetaQuery.isError}
                data={basicMetaQuery.data}
                component={RuleCoverage}
                transformData={(data) => ({ analysisData: data.rules })}
              />
            </Paper>
          </Box>
        </TabPanel>

        <TabPanel value={tabValue} index={1}>
          <Box sx={{ width: '60%', mx: 'auto' }}>
            <Paper sx={{ p: 2, mt: 2 }} elevation={0}>
              <MetricContainer
                isLoading={confusionMatrixQuery.isLoading}
                isError={confusionMatrixQuery.isError}
                data={confusionMatrixQuery.data}
                component={AccuracyScore}
                transformData={(data) => ({ assessmentPairs: data })}
              />
            </Paper>

            <Paper sx={{ p: 2, mt: 2 }} elevation={0}>
              <MetricContainer
                isLoading={analysisDataQuery.isLoading}
                isError={analysisDataQuery.isError}
                data={analysisDataQuery.data}
                component={ConfidenceScore}
                transformData={(data) => ({ analysisData: data })}
              />
            </Paper>

            <Paper sx={{ p: 2, mt: 2 }} elevation={0}>
              <MetricContainer
                isLoading={confusionMatrixQuery.isLoading}
                isError={confusionMatrixQuery.isError}
                data={confusionMatrixQuery.data}
                component={ConfusionMatrix}
                transformData={(data) => ({ assessmentPairs: data })}
              />
            </Paper>
          </Box>
        </TabPanel>

        <TabPanel value={tabValue} index={2}>
          <Box sx={{ width: '60%', mx: 'auto' }}>
            <Paper sx={{ p: 2, mt: 2 }} elevation={0}>
              {/* if no contractMapData then display: "Es gibt keine zu revalidierenden Klauseln" */}
              {contractMapData.length === 0 ? 
              <NoDataAvailable ifNoDataText="Es gibt keine zu revalidierenden Klauseln" /> 
              :
              <ContractAnalysis 
                contractMap={contractMapData} 
                reviews={invalidReviewsQuery.data || []} 
              />
              }
            </Paper>
          </Box>
        </TabPanel>
      </Box>
    </Box>
  );
};

export default AnalysisPage;