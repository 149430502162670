import React, {type ReactNode} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

interface FormDialogProps {
    open: boolean
    handleClose: () => void
    children: ReactNode
}

const FormDialog: React.FC<FormDialogProps> = ({
    open,
    handleClose,
    children
}) => {
    return (
        <div>
            <Dialog open={open} onClose={handleClose}>
                <DialogContent>{children}</DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </div>);
};

export default FormDialog;
