import React from 'react';
import { Outlet } from 'react-router-dom';
import NoPermission from './NoPermission';

interface RequireAuthProps {
    currentUser: any;
    allowedRoles?: string[];
}

// refetch reworked - currentUser is now only fetched in the App.tsx and then passed down to RequireAuth => only used there.
const RequireAuth: React.FC<RequireAuthProps> = ({ currentUser, allowedRoles }) => {

    // if there is a currentUser then render Outlet
    if (currentUser) {

        // if no allowedRoles then every user can access the page
        if (!allowedRoles) {
            // allowedRoles = ["admin", "user"];
            return <Outlet />
        }
        
        // if currentUser.role is not in allowedRoles then render NoPermission
        if (!allowedRoles.includes(currentUser.role)) {
            return <NoPermission />;
        }
        return <Outlet />;
    }

    // if there is no currentUser then App.tsx will handle login
    return null;
};

export default RequireAuth;
