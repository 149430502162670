import React from 'react';
import { Box } from '@mui/material';
import Alternative from './Alternative';
import { useGetAlternativesByClauseQuery } from '../../../features/Alternative/AlternativeApiSlice';
import LoadingComponent from '../../general/LoadingComponent';

interface AlternativeFormulationProps {
    deselectClause: () => void;
    caseNumber: string;
}

const AlternativeFormulation: React.FC<AlternativeFormulationProps> = ({ caseNumber }) => {
    const {
        data: alternatives,
        isLoading
    } = useGetAlternativesByClauseQuery({ clauseId: caseNumber });

    const sortedAlternatives = React.useMemo(() => {
        if (!alternatives) {
            return [];
        }

        const selected = alternatives.find(alt => alt.selected);
        const unselected = alternatives.filter(alt => !alt.selected).sort((a, b) => a.position - b.position);

        return selected ? [selected, ...unselected] : unselected;
    }, [alternatives]);

    return (!alternatives || isLoading ?
        <LoadingComponent text="Alternativen werden geladen..." /> :
        <Box sx={{
            height: '100%',
            overflow: 'auto'
        }}>
            {sortedAlternatives.map((alternative) => (
                <Box key={alternative._id}>
                    <Alternative alternative={alternative} />
                </Box>))}
        </Box>);
};

export default AlternativeFormulation;
