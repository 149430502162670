import React, { useMemo, useState, useEffect } from 'react';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Autocomplete, Box, Button, CircularProgress, IconButton, InputBase, Paper, Typography, useTheme } from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
import TemplateClauseTable from "./TemplateClauseTable";
import { useDeleteTemplateClauseMutation, useGetAllTemplateClausesQuery, usePostNewTemplateClauseMutation, useUpdateTemplateClauseMutation } from "../../features/TemplateClause/TemplateClauseApiSlice";
import { useGetAllTopicsQuery, useCreateTopicMutation } from "../../features/Topic/TopicApiSlice";
import { TemplateClause } from "../../features/TemplateClause/types";
import { Topic } from "../../features/Topic/types";
import { createTopicTemplateClauseObject } from '../../helper/topicRuleMap';

const TemplateClauseOverview = (): JSX.Element => {
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const [search, setSearch] = useState("");
    const [filterInProgress, setFilterInProgress] = useState(false);

    // Queries
    const { data: templateClauses, refetch: refetchClauses } = useGetAllTemplateClausesQuery();
    const { data: topics, refetch: refetchTopics } = useGetAllTopicsQuery();

    // Mutations
    const [deleteTemplateClause] = useDeleteTemplateClauseMutation();
    const [createTemplateClause, { isLoading: createLoading }] = usePostNewTemplateClauseMutation();
    const [updateTemplateClause, { isLoading: updateLoading }] = useUpdateTemplateClauseMutation();
    const [createTopic] = useCreateTopicMutation();

    const [currentTemplateClause, setCurrentTemplateClause] = useState<Partial<TemplateClause>>({
        topic: "",
        text: ""
    });

    const [localTopics, setLocalTopics] = useState<Topic[]>([]);

    useEffect(() => {
        if (topics) {
            setLocalTopics(topics.map(topic => topic));
        }
    }, [topics]);

    const onlyTopicTitle = useMemo(() => {
        return topics?.map(topic => topic.title);
    }, [topics]);

    const topicTemplateClauses = useMemo(() => {
        if (!templateClauses || !topics) return {};
        return createTopicTemplateClauseObject(templateClauses, topics);
    }, [templateClauses, topics]);

    // Filter template clauses based on search and topic mapping
    const filterTemplateClauses = useMemo(() => {
        if (!topicTemplateClauses || filterInProgress) {
            return [];
        }

        setFilterInProgress(true);
        
        try {
            // Flatten template clauses from all topics
            const allTemplateClauses = Object.entries(topicTemplateClauses).flatMap(([topicTitle, clauses]) => 
                clauses.map(clause => ({
                    ...clause,
                    topic: topicTitle // Use the topic title instead of ID
                }))
            );

            // Apply search filter if search term exists
            if (search) {
                return allTemplateClauses.filter(clause => 
                    clause.topic.toLowerCase().includes(search.toLowerCase()) ||
                    clause.text.toLowerCase().includes(search.toLowerCase())
                );
            }


            return allTemplateClauses;
        } finally {
            setFilterInProgress(false);
        }
    }, [topicTemplateClauses, search]);

    const handleCreateNewButton = (): void => {
        setCurrentTemplateClause({
            topic: "",
            text: ""
        });
        setOpen(true);
    };

    const handleClose = (): void => {
        setOpen(false);
    };

    const filter = createFilterOptions<string>();

    const saveEntry = async (): Promise<void> => {
        try {
            if (!currentTemplateClause.topic || !currentTemplateClause.text) {
                return;
            }

            const topicObj = localTopics.find(t => t.title === currentTemplateClause.topic);
            let topicId = topicObj?._id;

            // Create new topic if it doesn't exist
            if (!topicObj) {
                const result = await createTopic(currentTemplateClause.topic);
                if ('data' in result) {
                    setLocalTopics(prev => [...prev, result.data]);
                    topicId = result.data._id;
                } else {
                    console.error("Failed to create topic:", result.error);
                    return;
                }
            }

            // Update existing template clause
            if (currentTemplateClause._id) {
                await updateTemplateClause({
                    id: currentTemplateClause._id,
                    text: currentTemplateClause.text,
                    topic: topicId as string
                });
            } else {
                // Create new template clause
                await createTemplateClause({
                    topic: topicId as string,
                    text: currentTemplateClause.text
                });
            }

            await refetchClauses();
            await refetchTopics();
            handleClose();
        } catch (error) {
            console.error("Failed to save template clause:", error);
        }
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    };

    const handleDelete = async (templateClause: TemplateClause): Promise<void> => {
        try {
            await deleteTemplateClause(templateClause._id);
            await refetchClauses();
        } catch (error) {
            console.error("Failed to delete template clause:", error);
        }
    };

    const handleEdit = async (templateClause: TemplateClause): Promise<void> => {
        const topicTitle = localTopics.find(t => t._id === templateClause.topic)?.title || '';
        setCurrentTemplateClause({
            ...templateClause,
            topic: topicTitle
        });
        setOpen(true);
    };

    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
        }}>
            <Box sx={{
                mt: 5,
                width: '100%',
            }}>
                <Typography variant="h4">Musterklauseln</Typography>

                <Dialog fullWidth={true} maxWidth={'md'} open={open} onClose={handleClose}>
                    <DialogContent sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}>
                        <Box sx={{
                            width: '100%',
                            margin: 'auto'
                        }}>
                            <Typography variant="h5" sx={{ marginBottom: '1rem' }}>
                                {currentTemplateClause._id ? "Musterklausel bearbeiten" : "Neue Musterklausel erstellen"}
                            </Typography>
                            <Autocomplete
                                value={currentTemplateClause.topic || ''}
                                onChange={(_, newValue) => {
                                    setCurrentTemplateClause({ 
                                        ...currentTemplateClause, 
                                        topic: typeof newValue === 'string' ? newValue : ''
                                    });
                                }}
                                filterOptions={(options, params) => {
                                    const filtered = filter(options, params);
                                    const { inputValue } = params;

                                    // Prevent duplicate values
                                    const isExisting = (onlyTopicTitle ?? []).some(
                                        title => title.toLowerCase() === inputValue.toLowerCase()
                                    );
                                    
                                    // Add new option if it doesn't exist
                                    if (inputValue !== '' && !isExisting && typeof inputValue === 'string') {
                                        filtered.push(inputValue);
                                    }

                                    return filtered;
                                }}
                                options={localTopics.map(topic => topic.title)}
                                freeSolo
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Thema (zB.: Vergütung)"
                                        margin="normal"
                                        fullWidth
                                        sx={{
                                            width: '100%',
                                            marginBottom: '1rem',
                                            display: 'block',
                                            overflowWrap: 'break-word'
                                        }}
                                    />
                                )}
                            />
                            <TextField
                                value={currentTemplateClause.text}
                                label="Musterklausel..."
                                multiline
                                rows={4}
                                fullWidth
                                margin="normal"
                                sx={{
                                    width: '100%',
                                    marginBottom: '1rem',
                                    display: 'block',
                                    overflowWrap: 'break-word'
                                }}
                                onChange={(e) => setCurrentTemplateClause({ 
                                    ...currentTemplateClause, 
                                    text: e.target.value 
                                })}
                            />
                        </Box>

                        <Box sx={{
                            width: '100%',
                            maxWidth: '300px',
                            margin: 'auto',
                            marginTop: '2rem'
                        }}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                onClick={() => saveEntry()}
                                fullWidth
                            >
                                {createLoading || updateLoading ? 
                                    <CircularProgress color="secondary" /> : 
                                    'Speichern'
                                }
                            </Button>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Abbrechen</Button>
                    </DialogActions>
                </Dialog>

                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}>
                    <Button
                        variant="outlined"
                        startIcon={<AddIcon />}
                        sx={{ my: 4 }}
                        onClick={handleCreateNewButton}
                    >
                        Neue Musterklausel
                    </Button>
                    <Paper
                        component="form"
                        sx={{
                            p: '2px 4px',
                            display: 'flex',
                            alignItems: 'center',
                            backgroundColor: theme.palette.tertiary.main,
                            boxShadow: 'none',
                            height: 'fit-content'
                        }}
                    >
                        <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            placeholder="Suche eingeben"
                            inputProps={{ 'aria-label': 'Suche eingeben' }}
                            value={search}
                            onChange={handleSearchChange}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault();
                                }
                            }}
                        />
                        <IconButton type="button" sx={{ p: '10px' }} aria-label="Suche">
                            <SearchIcon />
                        </IconButton>
                    </Paper>
                </Box>

                <TemplateClauseTable
                    templateClauses={filterTemplateClauses}
                    handleDelete={handleDelete}
                    handleEdit={handleEdit}
                />
            </Box>
        </Box>
    );
};

export default TemplateClauseOverview;