import {Assessment} from '../features/Clause/types';
import {type Paragraph} from '../features/Paragraph/types';
import {Highlight} from '../components/contractManagement/classification/PdfViewer';

export function getHighlights(pageNumber: number, paragraphs: Paragraph[], pageWidthsInInches: number[], pageHeightsInInches: number[]): Highlight[] {

    const allClauses = paragraphs.flatMap(paragraph => paragraph.clauses);
    const highlights: Highlight[] = [];

    allClauses.forEach(clause => {
        if (clause.pageNumber!=pageNumber)return;
        const rects = transformPolygonToRects(clause.polygons, clause.pageNumber);
        if (rects==null)return;

        const latestReview = clause?.latestReview;
        const assessment = latestReview?.assessment ? latestReview.assessment : clause?.assessmentLLM;

        const color = clause.changed && !clause.assessmentUser ? 'rgba(211, 211, 211, 0.5)' : assessment === Assessment.UNFAIR ? 'rgba(226, 177, 60, 0.3)' : assessment === Assessment.VOID ? 'rgba(210, 77, 87, 0.3)' : assessment === Assessment.TOREASSESS ? 'rgba(60, 98, 173, 0.3)' : 'rgba(141, 178, 85, 0.3)';

        const highlight = {
            clauseId: clause._id,
            x: rects.x1,
            y: rects.y1,
            width: rects.width,
            height: rects.height,
            color: color
        };

        highlights.push(highlight);
    });

    function transformPolygonToRects(polygon: number[], pageNumber: number): { pageNumber: number, x1: number, y1: number, x2: number, y2: number, width: number, height: number } | null {
        if (polygon.length < 8) {
            console.error("POLYGON WRONG FORMAT "+polygon)
            return null;
        }
        const [x1, y1, , , x2, y2] = polygon;
        const pageWidthInInches = pageWidthsInInches[pageNumber - 1];
        const pageHeightInInches = pageHeightsInInches[pageNumber - 1];
        return {
            pageNumber,
            x1: (x1 / pageWidthInInches),
            y1: (y1 / pageHeightInInches),
            x2: (x2 / pageWidthInInches),
            y2: (y2 / pageHeightInInches),
            width: ((x2 / pageWidthInInches)) - ((x1 / pageWidthInInches)),
            height: ((y2 / pageHeightInInches)) - ((y1 / pageHeightInInches))
        };
    }

    return highlights;
}