import PersonIcon from '@mui/icons-material/Person';
import SmartToyOutlinedIcon from '@mui/icons-material/SmartToyOutlined';
import Box from '@mui/material/Box';
import React from 'react';
import {Assessment, type Clause} from '../../../features/Clause/types';
import {useGetReviewsByClauseQuery} from '../../../features/Review/ReviewApiSlice';

interface CustomBoxProps {
    clause: Clause
}

const TrafficLightIndication: React.FC<CustomBoxProps> = ({clause}) => {
    // TODO: Changed Assessment to Review
    // get the clause and find the latest llm review
    // get the clause and find the latest final/user review
    // display the traffic light indication for the reviews

    // find the review by id
    const { data: reviews, isLoading, isError } = useGetReviewsByClauseQuery(clause._id);

    // Find the latest LLM review
    const latestLLMReview = reviews?.filter(review => review.isLLM)
        .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())[0];

    // Find the latest user review
    const latestUserReview = reviews?.filter(review => !review.isLLM)
        .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())[0];

    
    const getBackgroundColor = (review: any) => {
        if (!review) return 'lightgrey';
        switch (review.assessment) {
            case Assessment.VALID:
                return 'assessment.valid';
            case Assessment.UNFAIR:
                return 'assessment.unfair';
            case Assessment.VOID:
                return 'assessment.void';
            case Assessment.TOREASSESS:
                return 'assessment.toreassess';
            default:
                return 'lightgrey';
        }
    };

    if (isLoading) return <Box>Loading...</Box>;
    if (isError) return <Box>Fehler!</Box>;

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            height: '100%'
        }}>
            {/* Box 1 */}
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                height: '100%',
                marginBottom: 0.5
            }}>
                <SmartToyOutlinedIcon/>
                <Box sx={{
                    height: '20px',
                    width: '20px',
                    marginLeft: 1,
                    borderRadius: '50%',
                    backgroundColor: clause.changed ? 'lightgrey' : getBackgroundColor(latestLLMReview)
                }}/>
            </Box>

            {/* Box 2 */}
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                height: '100%'
            }}>
                <PersonIcon/>
                <Box sx={{
                    height: '20px',
                    width: '20px',
                    marginLeft: 1,
                    borderRadius: '50%',
                    // backgroundColor: clause.changed ? 'lightgrey' : (!latestLLMReview?.isValid) ? 'assessment.toreassess' : getBackgroundColor(latestUserReview)
                    backgroundColor: clause.changed ? 'lightgrey' : getBackgroundColor(latestUserReview)
                }}/>
            </Box>
        </Box>);
};

export default TrafficLightIndication;
