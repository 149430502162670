import { create } from 'lodash';
import { apiSlice } from '../../app/api/apiSlice';
import { type Topic } from './types';

export const TopicApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getAllTopics: builder.query<Topic[], boolean | void>({
            query: () => `/topics}`
        }),
        createTopic: builder.mutation<Topic, string>({
            query: (title) => ({
                url: `/topics`,
                method: 'POST',
                body: { title }
            }),
            invalidatesTags: ['Topic']
        }),
        deleteTopic: builder.mutation<void, string>({
            query: (title) => ({
                url: `/topics/${title}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['Topic']
        }),
        updateTopic: builder.mutation<Topic, { id: string, title: string }>({
            query: ({ id, title }) => ({
                url: `/topics/update/${id}`,
                method: 'PUT',
                body: { title }
            }),
            invalidatesTags: ['Topic']
        }),
    })
});

export const {
    useGetAllTopicsQuery,
    useCreateTopicMutation,
    useDeleteTopicMutation,
    useUpdateTopicMutation
} = TopicApiSlice;