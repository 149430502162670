import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material';
import React from 'react';

interface ConfirmActionDialogProps {
    openDialog: boolean
    handleCloseDialog: () => void
    actionToConfirm: () => void
}

const ConfirmActionDialog: React.FC<ConfirmActionDialogProps> = ({
    openDialog,
    handleCloseDialog,
    actionToConfirm
}): any => {
    return (
        <Dialog open={openDialog} onClose={handleCloseDialog}>
            <DialogTitle>{'Bestätigung erforderlich'}</DialogTitle>
            <DialogContent>
                <DialogContentText> Sind Sie sicher, dass Sie diese Aktion durchführen möchten? </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseDialog}>Abbrechen</Button>
                <Button onClick={() => {
                    actionToConfirm();
                    handleCloseDialog();
                }} color="primary" autoFocus> Bestätigen </Button>
            </DialogActions>
        </Dialog>);
};

export default ConfirmActionDialog;
