import React from 'react';
import {Route, Routes} from 'react-router-dom';
import './App.css';
import ContractWorkflow from './components/contractManagement/ContractWorkflow';
import ContractsOverview from './components/contractManagement/ContractsOverview';
import Layout from './components/general/Layout';
import PageNotFound from './components/general/PageNotFound';
import Login from './components/userManagement/Login';
import UserOverview from './components/userManagement/UserOverview';
import TemplateClauseOverview from './components/templateClauseManagement/TemplateClauseOverview';
import RequireAuth from './components/general/RequireAuth';
import RuleOverview from "./components/ruleManagement/RuleOverview";
import TopicOverview from './components/topicManagement/TopicOverview';
import LoadingComponent from './components/general/LoadingComponent';
import AnalysisPage from './components/analysis/AnalysisPage';
import { useGetCurrentUserQuery } from './features/User/UserApiSlice';


// fetching the user now only in App.tsx
function App(): any {
    const { data: currentUser, isLoading, isError } = useGetCurrentUserQuery();

    if (isLoading) {
        return <LoadingComponent text="Lädt..." />;
    }

    if (isError || !currentUser) {
        return <Login />;
    }

    return (
        <Routes>
            <Route element={<Layout />}>
                <Route element={<RequireAuth currentUser={currentUser} allowedRoles={["admin"]} />}>
                </Route>
                <Route element={<RequireAuth currentUser={currentUser} allowedRoles={["admin", "user"]} />}>
                    <Route path="/users" element={<UserOverview />} />
                    <Route path="/" element={<ContractsOverview />} />
                    <Route path="/contracts" element={<ContractsOverview />} />
                    <Route path="/templateclauses" element={<TemplateClauseOverview />} />
                    <Route path="/rules" element={<RuleOverview />} />
                    <Route path="/topics" element={<TopicOverview />} />
                    <Route path="/contracts/:_id" element={<ContractWorkflow />} />
                    <Route path="/analysis" element={<AnalysisPage />} />
                    <Route path="*" element={<PageNotFound />} />
                </Route>
            </Route>
        </Routes>
    );
}

export default App;
