import React from 'react';
import {Box, Button, Card, Typography, useMediaQuery, useTheme} from '@mui/material';
import {useGetParagraphsAndClausesQuery} from '../../../features/Contract/ContractApiSlice';
import {useDispatch} from 'react-redux';
import {type Paragraph} from '../../../features/Paragraph/types';
import {useAppSelector} from '../../../hooks/typedReduxHooks';
import {setFilteredAlternative} from '../../../features/Alternative/AlternativeSlice';
import {useGetSelectedAlternativesByContractQuery} from '../../../features/Alternative/AlternativeApiSlice';
import {type IAlternative} from '../../../features/Alternative/types';
import {Assessment} from '../../../features/Clause/types';

enum AlternativeSelected {
    'Original', 'Alternative'
}

interface AlternativeFilterProps {
    contractId: string
}

const AlternativeFilter: React.FC<AlternativeFilterProps> = ({contractId}) => {
    const theme = useTheme();
    const isXl = useMediaQuery(theme.breakpoints.up('xl'));

    const dispatch = useDispatch();

    const assessmentTitle = [{
        lg: 'Origninal',
        xl: 'Originale Formulierung'
    }, {
        lg: 'Alternative',
        xl: 'Alternative Formulierung'
    }];

    const filteredAlternative: boolean[] = useAppSelector((state) => state.alternative.filteredAlternative);

    const {data: dataAlternatives} = useGetSelectedAlternativesByContractQuery(contractId ?? '');
    const alternatives: IAlternative[] = dataAlternatives ?? [];

    const {data: dataParagraphs} = useGetParagraphsAndClausesQuery(contractId ?? '');
    const paragraphs: Paragraph[] = dataParagraphs ?? [];

    const numberOfClauses: number = paragraphs.reduce((totalCount, paragraph) => {
        // Count the clauses that meet the condition in the current paragraph
        const countInParagraph = paragraph.clauses.filter(clause => {
            const latestReview = clause.latestReview;
            const assessment = latestReview?.assessment;
            return assessment === Assessment.UNFAIR || assessment === Assessment.VOID;
        }).length;

        // Add the count from this paragraph to the total count
        return totalCount + countInParagraph;
    }, 0);

    return (
        <Card sx={{
            marginLeft: 1,
            p: 1.5,
            width: '100%'
        }}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-around',
                width: '100%'
            }}>
                <Typography style={{
                    fontWeight: 'bold'
                }}> Filter: </Typography> {[AlternativeSelected.Original, AlternativeSelected.Alternative].map((type, index) => (
                <Button key={type} sx={{
                    // backgroundColor: 'tertiary.main',
                    flex: '1 1 auto',
                    mr: 1,
                    ml: 1,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    p: 0.5,
                    borderWidth: filteredAlternative[index] ? 1 : 1,
                    borderStyle: 'solid',
                    borderColor: filteredAlternative[index] ? 'primary.main' : 'lightgrey'
                }} style={{
                    borderRadius: 10
                }} onClick={() => {
                    const newfilteredAlternative = filteredAlternative.map((item, currentIndex) => currentIndex === index ? !item : item);
                    if (newfilteredAlternative.filter((filter) => filter).length > 0) {
                        dispatch(setFilteredAlternative(newfilteredAlternative));
                    }
                }}>
                    <Typography variant="body2" sx={{
                        fontWeight: 'bold',
                        width: '80%',
                        ml: 1
                    }}>
                        {isXl ? assessmentTitle[index].xl : assessmentTitle[index].lg}
                    </Typography>
                    <Card sx={{
                        backgroundColor: type === AlternativeSelected.Original ? '#dcf2ff' : '#faf2e5',
                        border: `1px solid ${type === AlternativeSelected.Original ? '#5b92cd' : '#e1c055'}`,
                        width: '20%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '4px 8px'
                    }} style={{
                        borderRadius: 8
                    }}>
                        <Typography sx={{
                            color: type === AlternativeSelected.Original ? '#5b92cd' : '#e1c055',
                            fontWeight: 'bold'
                        }}>
                            {type === AlternativeSelected.Original ? numberOfClauses - alternatives.length : alternatives.length}
                        </Typography>
                    </Card>
                </Button>))}
            </Box>

        </Card>);
};

export default AlternativeFilter;
