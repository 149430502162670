import Addchart from '@mui/icons-material/Addchart';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import DescriptionIcon from '@mui/icons-material/Description';
import GavelIcon from '@mui/icons-material/Gavel';
import LogoutIcon from '@mui/icons-material/Logout';
import Topic from '@mui/icons-material/Topic';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import { Box, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography, styled, type CSSObject, type Theme } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import React, { useEffect, useState } from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import UserInformation from '../userManagement/UserInformation';
import {Rule, Insights} from "@mui/icons-material";

const DrawerMenuBar = (): any => {
  const settings = ['Vertragsübersicht', 'Nutzerverwaltung', 'Formulierung', 'Regeln', 'Themen', 'Analysis', 'Ausloggen'];
  const menuIcons = [
    <DescriptionIcon key='Verträge' />,
    <Rule key='Regeln' />,
    <Topic key='Themen' />,
    <Addchart key='Formulierung' />,
    <PermContactCalendarIcon key='Nutzer' />,
    <Insights key="Analysis" />,
    <LogoutIcon key='Logout' />];
  const menuIconsSelected = [
    <DescriptionIcon key='Verträge' sx={{ color: 'white' }} />,
    <Rule key='Regeln' sx={{ color: 'white' }} />,
    <Topic key='Themen' sx={{ color: 'white' }} />,
    <Addchart key='Formulierung' sx={{ color: 'white' }} />,
    <PermContactCalendarIcon key='Nutzer' sx={{ color: 'white' }} />,
    <Insights key="Analysis" sx={{ color: 'white' }} />,
    <LogoutIcon key='Logout' />
  ];
  const links = ['/contracts', '/rules', '/topics', '/templateclauses', '/users', '/analysis', '/'];

  const naviagte = useNavigate();
  const location = useLocation();
  const [selectedSetting, setSelectedSetting] = useState(-1);

  useEffect(() => {
    setSelectedSetting(location.pathname.includes('/contracts') ? 0 : location.pathname.includes('/rules') ? 1 : location.pathname.includes('/topics') ? 2 : location.pathname.includes('/templateclauses') ? 3 : location.pathname.includes('/users') ? 4 : location.pathname.includes('/analysis') ? 5 : -1);
  }, [location.pathname]);

  const drawerWidth = 300;

  const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    overflowX: 'hidden',
    position: 'fixed',
    zIndex: 1200
  });

  const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: '65px',
    zIndex: 1
  });

  const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      boxSizing: 'border-box',
      borderRadius: '16px',
      ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme)
      }),
      ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme)
      })
    })
  );

  return (

    <>
      <Drawer
        variant="permanent"
        hideBackdrop
      >
        <Box display={'flex'} flexDirection="row" alignItems="center" margin={2} overflow={'hidden'} style={{ cursor: 'pointer' }} >
          <Link to="/">
            <GavelIcon sx={{ mr: 2, fontSize: '40px' }} color='primary' />
          </Link>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          height="100%"
        >
          <List>
            {settings.slice(0, -1).map((text, index) => (
              <ListItem key={text} disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: 'center',
                    px: 2.5,
                    borderRadius: '23px',
                    backgroundColor: selectedSetting === index ? 'primary.main' : 'none',
                    m: 1,
                    '&:hover': {
                      backgroundColor: selectedSetting === index ? 'black' : 'default'
                    }
                  }}
                  onClick={()=>naviagte(links[index])}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: 'auto',
                      justifyContent: 'center'
                    }}
                  >
                    {selectedSetting === index ? menuIconsSelected[index] : menuIcons[index]}
                  </ListItemIcon>
                  <ListItemText
                    primary={text}
                    sx={{
                      opacity: 0,
                      '& .MuiListItemText-primary': {
                        fontSize: '1rem',
                        fontWeight: 500,
                        color: selectedSetting === index ? 'white' : 'black'
                      }
                    }} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          <List>
            <UserInformation key={settings[settings.length - 1]} />
          </List>
        </Box>
      </Drawer>
    </>
  );
};

export default DrawerMenuBar;
