import {Box, Button, Card} from '@mui/material';
import type {PageViewport, PDFDocumentProxy, PDFPageProxy} from 'pdfjs-dist';
import * as pdfjsLib from 'pdfjs-dist';
import React, {useEffect, useRef, useState} from 'react';
import {useParams} from 'react-router-dom';
import {setExecuteScroll, setSelectedClauseId} from '../../../features/Clause/ClauseSlice';
import {Assessment} from '../../../features/Clause/types';
import {useGetContractByIdQuery, useGetParagraphsAndClausesQuery} from '../../../features/Contract/ContractApiSlice';
import {setSelectedParagraphId} from '../../../features/Paragraph/ParagraphSlice';
import {getHighlights} from '../../../helper/processOCR';
import {useAppDispatch, useAppSelector} from '../../../hooks/typedReduxHooks';
import LoadingSpinner from '../../general/LoadingSpinner';

export interface Highlight {
    clauseId: string
    x: number
    y: number
    width: number
    height: number
    color: string
}
pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.mjs`;

export default function PdfViewer () {
    const {_id} = useParams();
    const {data: contract} = useGetContractByIdQuery(_id);

    const url = process.env.REACT_APP_API_BASE_URL as string + '/contracts/files/' + contract?.file;

    const {data: paragraphs} = useGetParagraphsAndClausesQuery(_id ?? '');

    // FILTER THE ASSESSMENT OF THE PARAGRAPHS
    const filteredAssessment: boolean[] = useAppSelector((state) => state.contract.filteredAssessment);

    const containerRef = useRef<HTMLDivElement>(null);
    const [pdf, setPDF] = useState<PDFDocumentProxy | null>(null);
    const [pageNum, setCurrentPage] = useState(1);

    /* SLICE STATE CHANGES */
    const dispatch = useAppDispatch();

    useEffect(() => {
        async function loadPdf(): Promise<void> {
            try {
                const options = {
                    url,
                    withCredentials: true,
                    httpHeaders: {
                        mode: 'cors'
                    }
                };
                const loadingTask = pdfjsLib.getDocument(options);
                const pdf = await loadingTask.promise;
                setPDF(pdf);
                console.log("loaded pdf: "+pdf.numPages)
            } catch (error) {
                console.log('Error while loading PDF: ', error);
            }
        }

        loadPdf();
    }, []);

    useEffect(() => {
        renderCurrentPage();
    }, [pageNum, pdf, filteredAssessment, paragraphs]);

    async function renderCurrentPage(): Promise<void> {
        console.log("page render triggered")
        if (pdf==null || contract==null || paragraphs==null)return;

        //filter
        let filteredParagraphs = paragraphs;
        if (!filteredAssessment.every(item => !item)) {
            filteredParagraphs = paragraphs.map(paragraph => {
                // ONLY SHOW CLAUSES WHICH ARE FILTERED FOR
                const filteredClauses = paragraph.clauses.filter(clause => {
                    const latestReview = clause.latestReview;
                    const assessment = latestReview?.assessment;
                    
                    return filteredAssessment[Object.values(Assessment).indexOf(assessment as Assessment)] && !clause.changed;
                });

                return {
                    ...paragraph,
                    clauses: filteredClauses
                };
            }).filter(paragraph => paragraph.clauses.length > 0);
        }

        const highlights = getHighlights(pageNum, filteredParagraphs, contract.pageWidths, contract.pageHeights);
        // get page of pdf
        const page = await pdf.getPage(pageNum);

        //create context
        if (containerRef.current==null)return;
        while (containerRef.current.firstChild) {
            containerRef.current.removeChild(containerRef.current.firstChild);
        }
        const canvas = document.createElement('canvas');

        containerRef.current.appendChild(canvas);
        const oldViewport = page.getViewport({scale: 1});
        const context = canvas.getContext('2d');

        const width = containerRef.current.clientWidth;
        const pdfScale = width / oldViewport.width;
        const viewport = page.getViewport({scale: pdfScale});

        // Set the size of the canvas according to new size and scale
        canvas.style.width = `${width}px`;
        canvas.style.height = `${viewport.height}px`;
        canvas.width = width;
        canvas.height = viewport.height;

        //render the page
        if (context==null)return;
        await page.render({
            canvasContext: context,
            viewport: viewport
        }).promise.then(() => {
            try {
                if (highlights.length > 0) {
                    highlights.forEach(highlight => {
                        context.fillStyle = highlight.color;
                        context.fillRect(highlight.x * canvas.width, highlight.y * canvas.height, highlight.width * canvas.width, highlight.height * canvas.height);
                    });

                    /* ADD CLICKLISTENER TO AREAS OF RECTANGLE */
                    canvas.addEventListener('click', function (event) {
                        const x = event.offsetX;
                        const y = event.offsetY;

                        highlights.forEach(highlight => {
                            if (x > highlight.x * canvas.width && x < highlight.x * canvas.width + highlight.width * canvas.width && y > highlight.y * canvas.height && y < highlight.y * canvas.height + highlight.height * canvas.height) {
                                onHighlightClick(highlight.clauseId);
                            }
                        });
                    });

                    /* CHANGE CURSOR TO POINTER IF MOVED OVER RECTANGLE */
                    canvas.addEventListener('mousemove', function (event) {
                        const x = event.offsetX;
                        const y = event.offsetY;

                        let cursor = 'default';

                        highlights.forEach(highlight => {
                            if (x > highlight.x * canvas.width && x < highlight.x * canvas.width + highlight.width * canvas.width && y > highlight.y * canvas.height && y < highlight.y * canvas.height + highlight.height * canvas.height) {
                                cursor = 'pointer';
                            }
                        });
                        canvas.style.cursor = cursor;
                    });
                }
            } catch (error) {
                console.error('Error changing highlights: ', error);
            }
        }).catch((error) => {
            console.error('Error rendering page: ', error);
        });
    }

    const onHighlightClick = (clauseId: string): void => {
        let selectedParagraphId = '';
        if (paragraphs) {
            for (const paragraph of paragraphs) {
                for (const clause of paragraph.clauses) {
                    if (clause._id === clauseId) {
                        selectedParagraphId = paragraph._id;
                    }
                }
            }
        }
        dispatch(setSelectedParagraphId(''));
        dispatch(setSelectedParagraphId(selectedParagraphId));
        dispatch(setSelectedClauseId(''));
        dispatch(setSelectedClauseId(clauseId));
        dispatch(setExecuteScroll(true));
    };

    return (<>{pdf ?
        <Card sx={{
            boxSizing: 'border-box',
            mb: 2,
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column'
        }}>
            <div ref={containerRef} 
                style={{
                    flex: 1,
                    width: '100%',
                    height: '100%',
                    overflowY: 'scroll'
                }}>
            </div>
            <Box sx={{
                height: '40px',
                minHeight: '40px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <Button variant="text" onClick={() => {
                    setCurrentPage(Math.max(pageNum - 1, 1));
                }} disabled={pageNum === 1} style={{
                    fontSize: 'large',
                    padding: '0px',
                    borderRadius: '4px',
                    maxWidth: '30px',
                    maxHeight: '30px',
                    minWidth: '30px',
                    minHeight: '30px'
                }}> ‹ </Button>
                <Box sx={{
                    mx: 1
                }}>Seite {pageNum}</Box>
                <Button variant="text" onClick={() => {
                    setCurrentPage(Math.min(pageNum + 1, pdf.numPages));
                }} disabled={pageNum === pdf.numPages} style={{
                    fontSize: 'large',
                    padding: '0px',
                    borderRadius: '4px',
                    maxWidth: '30px',
                    maxHeight: '30px',
                    minWidth: '30px',
                    minHeight: '30px'
                }}> › </Button>
            </Box>
        </Card> :
        <LoadingSpinner/>}
    </>);
};
