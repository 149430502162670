import {apiSlice} from '../../app/api/apiSlice';
import {type Paragraph} from './types'; // Definieren Sie Ihren Paragraph-Typ

export const ParagraphApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getAllParagraphsByContract: builder.query<Paragraph[], string>({
            query: (contractId) => `/paragraphs/byContract/${contractId}`,
            providesTags: ['Paragraph']
        }),
        createParagraph: builder.mutation<Paragraph, FormData>({
            query: (formData) => ({
                url: `/paragraphs`,
                method: 'POST',
                body: formData
            }),
            invalidatesTags: ['Paragraph']
        }),
        getParagraphById: builder.query<Paragraph, string>({
            query: (paragraphId) => `/paragraphs/${paragraphId}`,
            providesTags: (result) => result != null ? [{
                type: 'Paragraph',
                id: result._id
            }] : []
        }),
        updateParagraph: builder.mutation<Paragraph, { paragraphId: string, requestBody: { content: string } }>({
            query: ({
                paragraphId,
                requestBody
            }) => ({
                url: `/paragraphs/${paragraphId}`,
                method: 'PUT',
                body: requestBody
            }),
            invalidatesTags: ['Paragraph']
        }),
        deleteParagraph: builder.mutation<{ message: string }, string>({
            query: (paragraphId) => ({
                url: `/paragraphs/${paragraphId}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['Paragraph']
        }),
        downgradeParagraph: builder.mutation<any, string>({
            query: (paragraphId) => ({
                url: `/paragraphs/downgrade/${paragraphId}`,
                method: 'PATCH'
            }),
            invalidatesTags: ['Paragraph']
        })
    })
});

export const {
    useGetAllParagraphsByContractQuery,
    useCreateParagraphMutation,
    useGetParagraphByIdQuery,
    useUpdateParagraphMutation,
    useDeleteParagraphMutation,
    useDowngradeParagraphMutation
} = ParagraphApiSlice;

export const {
    endpoints: {
        getAllParagraphsByContract,
        getParagraphById,
        createParagraph,
        updateParagraph,
        deleteParagraph,
        downgradeParagraph
    }
} = ParagraphApiSlice;
