import {Box, Card, CardContent, Typography} from '@mui/material';
import React, {useEffect, useRef, useState} from 'react';
import {Document, Page, pdfjs} from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import {type Contract, ContractState} from '../../features/Contract/types';
import {useGetUserByIdQuery} from '../../features/User/UserApiSlice';
import OptionsMenu from '../general/OptionsMenu';
import WorkflowStateBadge from './WorkflowStateBadge';

interface ContractItemProps {
    contract: Contract
    hideOptions?: boolean
    toReassess?: boolean
}

const ContractItem = ({contract, hideOptions = false}: ContractItemProps): any => {
    const [pdfFile, setPdfFile] = useState<File | null>(null);
    const [containerWidth, setContainerWidth] = useState<number>(0);
    const containerRef = useRef<HTMLDivElement>(null);

    
    const {data: contractUser} = useGetUserByIdQuery(contract.owner);

    useEffect(() => {
        const resizeObserver = new ResizeObserver((entries) => {
            for (const entry of entries) {
                setContainerWidth(entry.contentRect.width);
            }
        });

        if (containerRef.current) {
            resizeObserver.observe(containerRef.current);
        }

        return () => {
            resizeObserver.disconnect();
        };
    }, []);

    useEffect(() => {
        pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
        const fetchPdfFile = async (): Promise<void> => {
            if (contract?.file) {
                try {
                    const pdfUrl = process.env.REACT_APP_API_BASE_URL as string + '/contracts/files/' + contract.file;
                    const response = await fetch(pdfUrl, {
                        credentials: 'include',
                        mode: 'cors'
                    });
                    const blob = await response.blob();
                    const file = new File([blob], 'document.pdf', {type: 'application/pdf'});
                    setPdfFile(file);
                } catch (error) {
                    console.error('Fehler beim Laden der PDF-Datei:', error);
                }
            }
        };
        void fetchPdfFile();
    }, [contract]);

    return (
        <Card 
            ref={containerRef}
            sx={{
                cursor: 'pointer',
                boxShadow: 'none',
                height: '100%',
                maxHeight: '350px',
                '&:hover': {
                    bgcolor: '#ffffff99'
                }
            }}
            >
            <CardContent sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: '100%',
                maxHeight: '-webkit-fill-available'
            }}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: containerWidth < 300 ? 'column' : 'row',
                    justifyContent: 'space-between',
                    alignContent: 'start',
                    gap: 2,
                    mb: 2
                }}>
                    <Box width={containerWidth < 300 ? '100%' : '33%'} height={containerWidth < 300 ? '100px' : 'auto'} sx={{ position: 'relative', overflow: 'hidden' }}>
                    {pdfFile &&
                        <>
                            <Typography sx={{ position: 'absolute', top: '5px', left: '5px', backgroundColor: 'rgb(0, 0, 0, 0.05)', padding: '5px', borderRadius: '5px', zIndex: '10', backdropFilter: 'blur(10px)', textOverflow: 'ellipsis', }}>
                                {contract?.industry}
                            </Typography>
                            <Document file={pdfFile}>
                                <Page pageNumber={1} />
                            </Document>
                        </>
                    }
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '66%'
                    }}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            gap: 1,
                            mb: 2
                        }}>
                            <Typography sx={{fontWeight: 'bold', width: '100%'}}>
                                {`${new Date(contract?.createdAt).toLocaleDateString('de-DE', {
                                    day: '2-digit',
                                    month: '2-digit',
                                    year: '2-digit'
                                })}`}
                            </Typography>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'start',
                                alignItems: 'center',
                                visibility: hideOptions ? 'hidden' : 'visible'
                            }}> 
                                <OptionsMenu contract={contract}/>
                            </Box>
                        </Box>
                        <Typography variant="h6" mr={1} sx={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            fontWeight: 'bold'
                        }}>
                            {contract?.title}
                        </Typography>
                        <Typography mb={1} color="text.secondary" sx={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                        }}>
                            {contract?.description}
                        </Typography>
                        <Typography mt={0.5} color="text.secondary">
                            {contractUser ? `${contractUser.firstName} ${contractUser.lastName}` : 'Kein Nutzer bekannt.'}
                        </Typography>
                    </Box> 
                </Box>
                
                <WorkflowStateBadge contractState={contract.state}/>
            </CardContent>
        </Card>);
};

export default ContractItem;
