import {apiSlice} from '../../app/api/apiSlice';
import {IUser} from "./types";

export const UserApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getCurrentUser: builder.query<IUser, void>({
            query: () => `/users/ownProfile`,
            providesTags: (result, error) => (result ? [{type: 'User'}] : [])
        }),
        login: builder.mutation<IUser, { email: string, password: string }>({
            query: ({
                email,
                password
            }) => ({
                url: `/users/login`,
                method: 'POST',
                body: {
                    email,
                    password
                }
            }),
            invalidatesTags: ['User']
        }),
        logout: builder.mutation<void, void>({
            query: () => ({
                url: `/users/logout`,
                method: 'POST',
                invalidatesTags: ['User']
            })
        }),
        getAllUsers: builder.query<IUser[], void>({
            query: () => `/users`,
            providesTags: (result, error) => (result ? [{type: 'User'}] : [])
        }),
        getUserById: builder.query<IUser, string>({
            query: (userId) => `/users/${userId}`,
            providesTags: (result, error, userId) => (result !== undefined ? [{
                type: 'User',
                id: userId
            }] : [])
        }),
        createUser: builder.mutation<IUser, {
            email: string
            firstName: string
            lastName: string
            password: string
            title?: string
            position?: string
        }>({
            query: (requestBody) => ({
                url: `/users`,
                method: 'POST',
                body: requestBody
            }),
            invalidatesTags: ['User']
        }),
        updateUser: builder.mutation<IUser | string, {
            userId: string
            requestBody: {
                email?: string
                firstName?: string
                lastName?: string
                title?: string
                position?: string
                password?: string
            }
        }>({
            query: ({
                userId,
                requestBody
            }) => ({
                url: `/users/${userId}`,
                method: 'PUT',
                body: requestBody
            }),
            invalidatesTags: ['User']
        }),
        deleteUser: builder.mutation<any, string>({
            query: (userId) => ({
                url: `/users/${userId}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['User']
        })
    })
});

export const {
    useGetAllUsersQuery,
    useGetUserByIdQuery,
    useCreateUserMutation,
    useUpdateUserMutation,
    useDeleteUserMutation,
    useLoginMutation,
    useLogoutMutation,
    useGetCurrentUserQuery
} = UserApiSlice;

export const {
    endpoints: {
        getAllUsers,
        getUserById,
        createUser,
        updateUser,
        deleteUser,
        login,
        logout,
        getCurrentUser
    }
} = UserApiSlice;
