import React, { useEffect, useRef, useMemo } from 'react';
import ApexCharts from 'apexcharts';
import { ApexOptions } from 'apexcharts';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import { Rule } from '../../features/Rule/types';
import { HelpOutlineOutlined } from '@mui/icons-material';

interface RuleCorpusSizeProps {
  analysisData: Rule[];
}

const RuleCorpusSize: React.FC<RuleCorpusSizeProps> = ({ analysisData }) => {
  const chartRef = useRef<HTMLDivElement>(null);
  const chartInstance = useRef<ApexCharts | null>(null);

  const chartData = useMemo(() => {
    // Get only current versions and sort by date
    const currentRules = analysisData
      .filter(rule => rule.isCurrentVersion)
      .sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());

    // Group rules by day and count cumulative total
    const dailyData = new Map<string, number>();
    let cumulativeCount = 0;

    currentRules.forEach(rule => {
      const dayKey = new Date(rule.createdAt).toISOString().split('T')[0];
      cumulativeCount += 1;
      dailyData.set(dayKey, cumulativeCount);
    });

    // Convert Map to array of [timestamp, count] pairs
    return Array.from(dailyData.entries()).map(([day, count]) => [
      new Date(day).getTime(),
      count
    ]);
  }, [analysisData]);

  const options: ApexOptions = {
    chart: {
      defaultLocale: 'de',
      locales: [{
        name: 'de',
        options: {
          months: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
          shortMonths: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
          days: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
          shortDays: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa']
        }
      }],
      type: 'line',
      height: 350,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: true
      },
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 800,
      }
    },
    series: [{
      name: 'Prüfanleitungen',
      data: chartData,
    }],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
      width: 3
    },
    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'],
        opacity: 0.5,
      },
    },
    xaxis: {
      type: 'datetime',
      labels: {
        datetimeUTC: false,
        format: 'dd MMM yyyy'
      },
      title: {
        text: 'Zeitraum',
        style: {
          fontSize: '14px'
        }
      },
    },
    yaxis: {
      title: {
        text: 'Anzahl der Prüfanleitungen',
        style: {
          fontSize: '14px'
        }
      },
      labels: {
        formatter: (value) => Math.round(value).toString()
      }
    },
    tooltip: {
      x: {
        format: 'dd MMM yyyy'
      },
      y: {
        formatter: (value) => Math.round(value).toString()
      }
    },
    markers: {
      size: 4,
      hover: {
        size: 6
      }
    }
  };

  useEffect(() => {
    if (chartRef.current) {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
      chartInstance.current = new ApexCharts(chartRef.current, options);
      chartInstance.current.render();
    }
    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [options]);

  return (
    <Box sx={{ padding: 3 }} alignItems="center">
      <Typography variant="h5" gutterBottom>
        Anzahl der Prüfanleitungen im Zeitverlauf
        <Tooltip
          sx={{ padding: 0, marginLeft: 1 }}
          title={
            <Typography sx={{ margin: 0, padding: 0 }}>
              <b>Zeigt die Entwicklung der Anzahl der Prüfanleitungen über die Zeit.</b><br />
              Auf der X-Achse sieht man den Zeitraum.<br />
              Auf der Y-Achse die Gesamtzahl der aktiven Prüfanleitungen.<br />
              Ein steigender Trend bedeutet, dass neue Prüfanleitungen hinzugefügt wurden.
            </Typography>
          }
          componentsProps={{
            tooltip: {
              sx: {
                bgcolor: 'common.white',
                color: 'text.primary',
                boxShadow: 2,
              },
            },
          }}
        >
          <IconButton>
            <HelpOutlineOutlined />
          </IconButton>
        </Tooltip>
      </Typography>
      <div ref={chartRef} />
    </Box>
  );
};

export default RuleCorpusSize;