import {Box, Typography} from '@mui/material';
import React from 'react';
import LoadingSpinner from './LoadingSpinner';

interface LoadingComponentProps {
    text: string
}

const LoadingComponent: React.FC<LoadingComponentProps> = ({text}) => {
    return (
        <Box sx={{
            height: '80%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
        }}>
            <Box mb={1}>
                <LoadingSpinner/>
            </Box>
            <Typography>{text}</Typography>
        </Box>);
};

export default LoadingComponent;
